import { ScenarioState } from 'Features/participant/workspace/types';
import { NotStartedChip } from 'Features/participant/workspace/dashboard/components/NotStartedChip';
import { InProgressChip } from 'Features/participant/workspace/dashboard/components/InProgressChip';
import { CompleteChip } from 'Features/participant/workspace/dashboard/components/CompleteChip';

export const scenarioCardWidth = 520;

export type SortKind = 'nameAsc' | 'nameDesc' | 'progressAsc' | 'progressDesc';
export type Order = 'asc' | 'desc';

export const DASHBOARD_SORT_OPTIONS: { value: SortKind; idx: number }[] = [
    {
        value: 'nameAsc',
        idx: 0,
    },
    {
        value: 'nameDesc',
        idx: 1,
    },
    {
        value: 'progressAsc',
        idx: 2,
    },
    {
        value: 'progressDesc',
        idx: 3,
    },
];

export const SORTING_VALUES: Record<SortKind, { field: string; direction: Order }> = {
    nameAsc: { field: 'name', direction: 'asc' },
    nameDesc: { field: 'name', direction: 'desc' },
    progressAsc: { field: 'percents', direction: 'asc' },
    progressDesc: { field: 'percents', direction: 'desc' },
};

export type ChipStatus = 'notStarted' | 'inProgress' | 'complete';

export const Chips: Record<ChipStatus, React.ElementType> = {
    notStarted: NotStartedChip,
    inProgress: InProgressChip,
    complete: CompleteChip,
};

export const chipStatuses: Record<ScenarioState, ChipStatus> = {
    [ScenarioState.ready]: 'notStarted',
    [ScenarioState.started]: 'inProgress',
    [ScenarioState.finished]: 'complete',
    [ScenarioState.loading]: 'notStarted',
    [ScenarioState.expired]: 'notStarted',
};
