import { Tabs, Tab } from '@mui/material';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Sex } from 'Shared/types';

export function SexSelect(props: FieldRenderProps<any>) {
    const handleSexChange = (_event: any, newSex: Sex) => {
        props.input.onChange(newSex);
    };
    const { t: getLabel } = useTranslation();
    return (
        <Tabs value={props.input.value || Sex.Male} onChange={handleSexChange}>
            <Tab id="sex-male" label={getLabel('register.form.gender.male')} value={Sex.Male} />
            <Tab id="sex-female" label={getLabel('register.form.gender.female')} value={Sex.Female} />
        </Tabs>
    );
}
