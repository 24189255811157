import { useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IProgress } from 'Features/participant/workspace/scenario/types';
import { useRecorder } from 'Features/participant/workspace/scenario/hooks';
import {
    finishStep,
    setActionButton,
    getStepperIds,
    getCurrentStep,
} from 'Features/participant/workspace/scenario/slice';
import { chunkDurationSec } from 'Features/participant/workspace/scenario/constants';
import {
    useObservable,
    videoEvent$,
    videoEventTypes,
} from 'Features/participant/workspace/scenario/observables/videoEvent';
import { participantMutations } from 'Features/participant/workspace/scenario/queries';

export const useVideoRecorder = (requiredWatchedPercentage: number) => {
    const dispatch = useDispatch();
    const stepperIds = useSelector(getStepperIds);
    const currentStep = useSelector(getCurrentStep)?.scenarioStep;
    const { mutateAsync: finishSession } = participantMutations.useFinishScenarioStepSession();

    const { state } = useObservable(videoEvent$);

    const [isVideoStarted, setIsVideoStarted] = useState<boolean>(false);
    const [progressPercents, setProgressPercents] = useState<number>(0);

    const { isWebcamReady, errorMessage, recorder, webcamRef, finishReactionRecording } = useRecorder();

    const handlePlay = useCallback(() => {
        setIsVideoStarted(true);
        if (recorder) {
            if (recorder.state === 'paused') {
                recorder.resume();
            } else {
                recorder.state !== 'recording' && recorder.start(chunkDurationSec * 1000);
            }
        }
    }, [recorder]);

    const handleSeek = useCallback(() => {
        if (recorder && recorder.state === 'recording') {
            recorder.pause();
        }
    }, [recorder]);

    const handleEnded = useCallback(async () => {
        currentStep?.id && finishReactionRecording(currentStep?.id);
        // if (recorder) {
        //     const stepId = currentStep?.id;
        //     if (stepId) {
        //         recorder.ondataavailable = async (event: BlobEvent) => {
        //             await handleWebcamDataAvailable(stepId, event);
        //             await completeRecording(stepId);
        //         };
        //         recorder.state !== 'inactive' && recorder.stop();
        //     }
        // }
        const sessionId = stepperIds?.stepSessionId;
        if (sessionId) {
            await finishSession({ urlParams: { id: sessionId } });
        }
        dispatch(finishStep());
        dispatch(
            setActionButton({
                onClick: undefined,
            }),
        );
    }, [dispatch, currentStep?.id, stepperIds?.stepSessionId, finishReactionRecording, finishSession]);

    const handlePause = useCallback(() => {
        if (recorder?.state === 'recording') {
            recorder.pause();
        }
    }, [recorder]);

    const handleVideoProgress = useCallback((data: IProgress) => {
        setProgressPercents(data.percents);
    }, []);

    const handlers = useMemo(
        () => ({
            [videoEventTypes.onParticipantPlayerPlay]: handlePlay,
            [videoEventTypes.onParticipantPlayerPause]: handlePause,
            [videoEventTypes.onParticipantPlayerSeek]: handleSeek,
            [videoEventTypes.onParticipantPlayerEnded]: handleEnded,
            [videoEventTypes.onParticipantPlayerVideoProgress]: handleVideoProgress,
        }),
        [handlePlay, handlePause, handleSeek, handleEnded, handleVideoProgress],
    );

    useEffect(() => {
        if (state?.status) {
            const handler = handlers[state?.status];
            handler instanceof Function && handler(state?.data);
        }
    }, [state, handlers]);

    useEffect(() => {
        dispatch(
            setActionButton({
                onClick: handleEnded,
                disabled: true,
            }),
        );
    }, [handleEnded, dispatch]);

    useEffect(() => {
        const isNotRequiredToWatch = !requiredWatchedPercentage && isVideoStarted;
        const isRequiredPercentageAlreadyWatched =
            requiredWatchedPercentage !== 0 && progressPercents >= requiredWatchedPercentage;

        if (isNotRequiredToWatch || isRequiredPercentageAlreadyWatched) {
            dispatch(setActionButton({ disabled: false }));
        }
    }, [requiredWatchedPercentage, progressPercents, dispatch, isVideoStarted]);

    return { isWebcamReady, errorMessage, webcamRef, recorder };
};
