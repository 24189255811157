import { Typography, Divider } from '@mui/material';

function SectionHeader(props: { text: string; style?: any }) {
    return (
        <div style={props.style}>
            <Typography variant="h4">{props.text}</Typography>
            <Divider style={{ marginTop: '20px' }} />
        </div>
    );
}

export default SectionHeader;
