import ReactMarkdown from 'react-markdown';

import './styles.css';

interface IMarkdownProps {
    children: string;
}

export default function WantentMarkdown({ children }: IMarkdownProps) {
    return <ReactMarkdown className="markdown">{children}</ReactMarkdown>;
}
