import { Button, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import * as React from 'react';

export interface IInfoDialogProps {
    isOpen: boolean;
    title?: string;
    onClose: () => void;
    content: React.ReactNode;
    buttonLabel?: string;
    buttonProps?: ButtonProps;
}

export function InfoDialog(props: IInfoDialogProps) {
    const { isOpen, onClose, content, buttonLabel, title, buttonProps } = props;
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" autoFocus {...buttonProps}>
                    {buttonLabel || 'OK'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
