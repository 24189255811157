import * as React from 'react';
import { styled, SxProps, Theme } from '@mui/material/styles';

const StyledHidingPanel = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isShown',
})<{ isShown: boolean }>(({ isShown }) => ({
    right: 0,
    left: 0,
    padding: '10px',
    position: 'absolute',
    display: isShown ? 'flex' : 'none',
    bottom: 0,
}));

interface IHidingPanelProps {
    isShown: boolean;
    sx?: SxProps<Theme>;
    id?: string;
    children: React.ReactNode;
}

export const HidingPanel: React.FunctionComponent<IHidingPanelProps> = ({ isShown, sx, id, children }) => {
    return (
        <StyledHidingPanel id={id} isShown={isShown} sx={{ ...sx }}>
            {children}
        </StyledHidingPanel>
    );
};
