import { useEffect, useCallback, useMemo, useState } from 'react';
import { VideoType } from 'Shared/types';
import { participantMutations, participantQueries } from 'Features/participant/workspace/scenario/queries';
import { CustomPlayer } from 'Features/participant/workspace/scenario/components/WantentShakaPlayer';
import { YoutubePlayer } from 'Features/participant/workspace/scenario/components/YoutubePlayer';
import { errorSnack } from 'Shared/components/snackbars/Snackbar';
import { IProgress } from 'Features/participant/workspace/scenario/types';
import { useActionButton } from 'Features/participant/workspace/scenario/hooks/useActionButton';
import { finishStep, selectStepperState } from 'Features/participant/workspace/scenario/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
    useObservable,
    videoEvent$,
    videoEventTypes,
} from 'Features/participant/workspace/scenario/observables/videoEvent';
import { ScenarioPaper } from './shared/ScenarioPaper';
import { useTranslation } from 'react-i18next';
import { Alert } from '@watched-tech/wantent-ui';
import { CircularProgress } from '@mui/material';

interface IVideoStepPlayerProps {
    videoType: VideoType;
    video: {
        videoForReactionId: string;
        requiredWatchedPercentage: number;
    };
}

const uriMap: Record<VideoType, string> = {
    [VideoType.videoContent]: '/api/me/videos/custom',
    [VideoType.watermarkedContent]: '/api/me/videos/watermarked',
    [VideoType.youtube]: '',
};

const RenderCustomPlayer = ({ videoType, video }: IVideoStepPlayerProps) => {
    const { t: getLabel } = useTranslation();
    const { state } = useObservable(videoEvent$);
    const { mutateAsync: finishSession } = participantMutations.useFinishScenarioStepSession();
    const [interruptedMsg, setInterruptedMsg] = useState<string>();
    const [reqProgressAchieved, setReqProgressAchieved] = useState(false);
    const { ids } = useSelector(selectStepperState);
    const uri = uriMap[videoType];
    const {
        isLoading,
        data: customVideoObj,
        error: getCustomVideoObjError,
    } = participantQueries.useCustomVideoLinkObj(
        { uri, videoForReactionId: video.videoForReactionId, stepSessionId: ids?.stepSessionId },
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    );

    const actionButton = useActionButton();
    const dispatch = useDispatch();

    useEffect(() => {
        actionButton.setDisabled(true);
    }, [video.videoForReactionId]);

    const handleFinished = useCallback(async () => {
        if (!ids) throw Error('ids is undefined, cannot finish step');
        await finishSession({ urlParams: { id: ids.stepSessionId } });
        dispatch(finishStep());
    }, [dispatch, ids, finishSession]);

    const handleProgress = useCallback(
        (data: IProgress) => {
            const requiredProgressAchieved =
                video.requiredWatchedPercentage !== 0 && data.percents >= video.requiredWatchedPercentage;
            if (requiredProgressAchieved) {
                // Allow to finish step and setup listener for video end event
                if (!reqProgressAchieved) {
                    actionButton.setDisabled(false);
                    actionButton.setOnClick(handleFinished);
                    setReqProgressAchieved(true);
                }
            }
        },
        [actionButton, handleFinished, video.requiredWatchedPercentage, reqProgressAchieved],
    );

    const handlers = useMemo(
        () => ({
            [videoEventTypes.onParticipantPlayerEnded]: handleFinished,
            [videoEventTypes.onParticipantPlayerVideoProgress]: handleProgress,
        }),
        [handleFinished, handleProgress],
    );

    useEffect(() => {
        if (state?.status) {
            const handler = handlers[state.status];
            handler instanceof Function && handler(state.data);
        }
    }, [state, handlers]);
    return (
        <ScenarioPaper
            title={getLabel('participant.video.title')}
            alert={interruptedMsg ? <Alert type="warning" text={interruptedMsg} /> : undefined}
        >
            {isLoading && <CircularProgress />}
            {getCustomVideoObjError instanceof Error && errorSnack(getCustomVideoObjError.message)}
            {customVideoObj?.link && (
                <CustomPlayer
                    link={customVideoObj?.link}
                    interruptedMsg={interruptedMsg}
                    setInterruptedMsg={setInterruptedMsg}
                />
            )}
        </ScenarioPaper>
    );
};

const RenderYoutubePlayer = ({ videoForReactionId }: any) => {
    const { t: getLabel } = useTranslation();
    const actionButton = useActionButton();
    const { ids } = useSelector(selectStepperState);
    const { mutateAsync: finishSession } = participantMutations.useFinishScenarioStepSession();
    const dispatch = useDispatch();
    const { state } = useObservable(videoEvent$);

    const handleFinished = useCallback(async () => {
        if (!ids) throw Error('ids is undefined, cannot finish step');
        await finishSession({ urlParams: { id: ids.stepSessionId } });
        dispatch(finishStep());
    }, [dispatch, ids, finishSession]);

    const handleStart = useCallback(() => {
        actionButton.setOnClick(handleFinished);
        actionButton.setDisabled(false);
    }, [actionButton, handleFinished]);

    useEffect(() => {
        if (state?.status) {
            state.status === videoEventTypes.onParticipantPlayerStart && handleStart();
        }
    }, [state, handleStart]);

    useEffect(() => {
        actionButton.setDisabled(true);
    }, []);

    const {
        data: youtubeVideoObj,
        error: getYoutubeVideoObjError,
        isLoading,
    } = participantQueries.useYoutubeVideoLinkObj(videoForReactionId, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
    return (
        <ScenarioPaper title={getLabel('participant.video.title')}>
            {isLoading && <CircularProgress />}
            {getYoutubeVideoObjError instanceof Error && errorSnack(getYoutubeVideoObjError.message)}
            {!isLoading && youtubeVideoObj?.link && <YoutubePlayer link={youtubeVideoObj.link} />}
        </ScenarioPaper>
    );
};

export const VideoStepPlayer = ({ videoType, video }: IVideoStepPlayerProps) => {
    if (videoType === VideoType.youtube) {
        return <RenderYoutubePlayer videoForReactionId={video.videoForReactionId} />;
    }
    if (!video.videoForReactionId) throw new Error('videoForReactionId is not defined');
    return <RenderCustomPlayer videoType={videoType} video={video} />;
};
