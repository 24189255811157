import { StepType } from 'Features/participant/workspace/scenario/types';
import { CreateTenantModel } from 'Features/manager/shared/types';
import { IQueryCallbackId } from 'Features/manager/shared/queries/queries';
import { AuthenticatedMutationOptions, useAuthenticatedMutation } from 'Src/shared/hooks/queries';
import { Quota } from 'src/pages/manager/scenarios/ManagerEditQuotas';
import { QuestionOption } from 'Shared/types';

export interface IAddVideoStepToEditedScenarioResponse {
    id: string;
    isGazeRequired: boolean;
    isRecordingRequired: boolean;
    isWebcamCheckRequired: boolean;
    name: string;
    requiredWatchedPercentage: number;
    stepPosition: number;
    stepType: StepType;
    videoReactionSource: { id: string };
}

export interface AudienceBucket {
    scenarioId: string;
    name: string;
    participantCount: number;
    description: string;
    uiLocale: string;
}

export enum ScreenerQuestionType {
    radioPickOneQuestion = 'radioPickOneQuestion',
    pickManyQuestion = 'pickManyQuestion',
    pickOneQuestion = 'pickOneQuestion',
}

export enum QualificationAction {
    // for multiple choice questions
    mustSelect = 'mustSelect',
    maySelect = 'maySelect',
    mustSelectAtLeastOne = 'mustSelectAtLeastOne',
    // for single choice questions
    qualify = 'qualify',
    // universal
    disqualify = 'disqualify',
}

export interface AbstractQuestion {
    id: string;
    position: number;
    isOptionsRandomized: boolean;
    type: string;
    text: string;
    image?: {
        width: number;
        height: number;
        url: string;
    };
}

export interface OptionsQuestion extends AbstractQuestion {
    options: QuestionOption[];
}

export interface ScreenerQuestion extends AbstractQuestion {
    type: ScreenerQuestionType;
    options: QuestionOption[];
}

export interface ScreenerModel {
    title: string;
    screenerQuestions: ScreenerQuestion[];
}

export interface ICreateFocusGroupValues {
    title: string;
    participantIds: any;
}

export const managerMutations = {
    useCreateFocusGroup: (options?: AuthenticatedMutationOptions) =>
        useAuthenticatedMutation<undefined, ICreateFocusGroupValues, unknown, any>(
            ['manager.createFocusGroup'],
            {
                url: '/api/focusgroups',
                method: 'post',
            },
            options,
        ),
    useCreateTenant: (options?: AuthenticatedMutationOptions) =>
        useAuthenticatedMutation<undefined, CreateTenantModel, any>(
            ['admin.createTenant'],
            {
                url: '/api/tenants',
                method: 'post',
            },
            options,
        ),
    useDeleteSession: (options?: AuthenticatedMutationOptions<{ id: string }>) =>
        useAuthenticatedMutation<IQueryCallbackId, undefined, any>(
            ['manager.deleteSession'],
            {
                url: (params) => `/api/scenariosessions/${params.id}`,
                method: 'delete',
            },
            options,
        ),

    useDeleteRegisterLink: (
        options?: AuthenticatedMutationOptions<{
            focusGroupId: string;
            linkId: string;
        }>,
    ) =>
        useAuthenticatedMutation<
            {
                focusGroupId: string;
                linkId: string;
            },
            undefined,
            any
        >(
            ['manager.deleteRegistrationLing'],
            {
                url: (params) => `/api/focusgroups/${params.focusGroupId}/register_links/${params.linkId}`,
                method: 'delete',
            },
            options,
        ),

    useCreateScenario: (options?: AuthenticatedMutationOptions) =>
        useAuthenticatedMutation<undefined, FormData, any>(
            ['manager.createScenario'],
            {
                url: '/api/scenarios',
                method: 'post',
            },
            options,
        ),

    useDeleteFocusGroup: (options?: AuthenticatedMutationOptions<{ id: string }>) =>
        useAuthenticatedMutation<IQueryCallbackId, undefined, any>(
            ['manager.deleteFocusGroup'],
            {
                url: (params) => `/api/focusgroups/${params.id}`,
                method: 'delete',
            },
            options,
        ),

    useGenerateRegisterLinks: (options?: AuthenticatedMutationOptions<{ id: string }>) =>
        useAuthenticatedMutation<IQueryCallbackId, { count: number; presetPassword?: string }, any>(
            ['manager.generateRegisterLinks'],
            {
                url: (params) => `/api/focusgroups/${params.id}/generate_register_links`,
                method: 'post',
            },
            options,
        ),

    useAddSurvey: (options?: AuthenticatedMutationOptions) =>
        useAuthenticatedMutation<undefined, any, { id: string; surveyElements: { id: string; position: number }[] }>(
            ['manager.addSurvey'],
            {
                url: '/api/surveys',
                method: 'post',
            },
            options,
        ),

    useEditSurvey: (options?: AuthenticatedMutationOptions) =>
        useAuthenticatedMutation<undefined, any, { id: string; surveyElements: { id: string; position: number }[] }>(
            ['manager.editSurvey'],
            {
                url: `/api/surveys/edit`,
                method: 'put',
            },
            options,
        ),

    usePatchSurvey: (options?: AuthenticatedMutationOptions<{ surveyId: string }>) =>
        useAuthenticatedMutation<{ surveyId: string }, any, { title: string }>(
            ['manager.patchSurvey'],
            {
                url: (params) => `/api/surveys/${params.surveyId}`,
                method: 'patch',
            },
            options,
        ),

    useDeleteScenario: (options?: AuthenticatedMutationOptions<{ id: string }>) =>
        useAuthenticatedMutation<IQueryCallbackId, undefined, any>(
            ['manager.deleteScenario'],
            {
                url: (params) => `/api/scenarios/${params.id}`,
                method: 'delete',
            },
            options,
        ),

    useGenerateSurveyReport: (options?: AuthenticatedMutationOptions<{ id: string; ageBins: string }, Blob>) =>
        useAuthenticatedMutation<{ id: string; ageBins: string; pivotQuestions: string[] }, Blob>(
            ['manager.generateSurveyReport'],
            {
                url: (params) =>
                    `/api/scenarios/${params.id}/survey_report?ageBins=${
                        params.ageBins
                    }&pivotQuestions=${params.pivotQuestions.join(',')}`,
                method: 'GET',
                config: {
                    responseType: 'blob',
                },
            },
            options,
        ),

    useGenerateAIReport: (options?: AuthenticatedMutationOptions<{ id: string }, FormData, Blob>) =>
        useAuthenticatedMutation<IQueryCallbackId, FormData, Blob>(
            ['manager.generateAIReport'],
            {
                url: (params) => `/api/scenarios/${params.id}/ai_report`,
                method: 'POST',
                config: {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    responseType: 'blob',
                },
            },
            options,
        ),

    useUpdateScenarioNameAndImage: (options?: AuthenticatedMutationOptions<{ scenarioId: string }>) =>
        useAuthenticatedMutation<{ scenarioId: string }>(
            ['manager.editScenario'],
            {
                url: (params) => `/api/scenarios/${params.scenarioId}`,
                method: 'patch',
            },
            options,
        ),

    useAddDCM: (options?: AuthenticatedMutationOptions<{ scenarioId: string }>) =>
        useAuthenticatedMutation<{ scenarioId: string }>(
            ['manager.addDCM'],
            {
                url: (params) => `/api/scenarios/${params.scenarioId}/data_collection_methods`,
                method: 'post',
            },
            options,
        ),
    useAssignDCMToBucket: (options?: AuthenticatedMutationOptions<{ bucketId: string }>) =>
        useAuthenticatedMutation<{ bucketId: string }, { dataCollectionMethodId: string }>(
            ['manager.assignDCMToBucket'],
            {
                url: (params) => `/api/v2/audiencebuckets/${params.bucketId}/data_collection_methods`,
                method: 'put',
            },
            options,
        ),
    usePutScreener: (options?: AuthenticatedMutationOptions<{ bucketId: string }>) =>
        useAuthenticatedMutation<{ bucketId: string }, ScreenerModel>(
            ['manager.putScreener'],
            {
                url: (params) => `/api/v2/audiencebuckets/${params.bucketId}/screener`,
                method: 'put',
            },
            options,
        ),
    useCreateAudienceBucket: (options?: AuthenticatedMutationOptions<{ scenarioId: string }>) =>
        useAuthenticatedMutation<AudienceBucket>(
            ['manager.addAudienceBucket'],
            {
                url: `/api/v2/audiencebuckets`,
                method: 'post',
            },
            options,
        ),

    usePutQuotas: (options?: AuthenticatedMutationOptions<{ bucketId: string }>) =>
        useAuthenticatedMutation<{ bucketId: string }, { quotas: Quota[] }>(
            ['manager.putQuotas'],
            {
                url: (params) => `/api/v2/audiencebuckets/${params.bucketId}/quotas`,
                method: 'put',
            },
            options,
        ),
    useRemoveDcm: (options?: AuthenticatedMutationOptions<{ scenarioId: string; dcmId: string }>) =>
        useAuthenticatedMutation<{ scenarioId: string; dcmId: string }>(
            ['manager.removeDCM'],
            {
                url: (params) => `/api/scenarios/${params.scenarioId}/data_collection_methods/${params.dcmId}`,
                method: 'delete',
            },
            options,
        ),

    useAddSteps: (options?: AuthenticatedMutationOptions<{ scenarioId: string }>) =>
        useAuthenticatedMutation<{ scenarioId: string }, FormData, IAddVideoStepToEditedScenarioResponse[]>(
            ['manager.addSteps'],
            {
                url: (params) => `/api/scenarios/${params.scenarioId}/steps`,
                method: 'post',
            },
            options,
        ),

    useRemoveStep: (options?: AuthenticatedMutationOptions<{ scenarioId: string; stepId: string }>) =>
        useAuthenticatedMutation<{ scenarioId: string; stepId: string }>(
            ['manager.removeStep'],
            {
                url: (params) => `/api/scenarios/${params.scenarioId}/scenario_steps/${params.stepId}`,
                method: 'delete',
            },
            options,
        ),

    useChangeStepOrder: (options?: AuthenticatedMutationOptions<{ scenarioId: string }>) =>
        useAuthenticatedMutation(
            ['manager.changeStepOrder'],
            {
                url: (params) => `/api/scenarios/${params.scenarioId}/steps_order`,
                method: 'post',
            },
            options,
        ),

    useEditStep: (options?: AuthenticatedMutationOptions<{ stepId: string }>) =>
        useAuthenticatedMutation<{ stepId: string }, object, undefined>(
            ['manager.editStep'],
            {
                url: (params) => `/api/scenariosteps/${params.stepId}`,
                method: 'put',
            },
            options,
        ),

    useUploadPDF: (options?: AuthenticatedMutationOptions<{ stepId: string }>) =>
        useAuthenticatedMutation<{ stepId: string }, any, undefined>(
            ['manager.uploadPDF'],
            {
                url: (params) => `/api/presentationsteps/${params.stepId}/file`,
                method: 'put',
                config: {
                    headers: { 'Content-Type': 'application/pdf' },
                },
            },
            options,
        ),
    useUploadSurveyImage: (options?: AuthenticatedMutationOptions<{ surveyId: string; questionId: string }>) =>
        useAuthenticatedMutation<
            { surveyId: string; questionId: string },
            any,
            { link: string; headers: Record<string, string> }
        >(
            ['manager.uploadSurveyImage'],
            {
                url: (params) => `/api/surveys/${params.surveyId}/questions/${params.questionId}/image`,
                method: 'put',
            },
            options,
        ),

    useDeleteSurveyImage: (options?: AuthenticatedMutationOptions<{ surveyId: string; questionId: string }>) =>
        useAuthenticatedMutation<{ surveyId: string; questionId: string }, any, undefined>(
            ['manager.deleteSurveyImage'],
            {
                url: (params) => `/api/surveys/${params.surveyId}/questions/${params.questionId}/image`,
                method: 'delete',
            },
            options,
        ),

    useUploadLanding: (options?: AuthenticatedMutationOptions<{ stepId: string }>) =>
        useAuthenticatedMutation<{ stepId: string }, any, undefined>(
            ['manager.uploadLanding'],
            {
                url: (params) => `/api/landingsteps/${params.stepId}/file`,
                method: 'put',
                config: {
                    headers: { 'Content-Type': 'image/jpeg' },
                },
            },
            options,
        ),

    useCopyScenario: (options?: AuthenticatedMutationOptions<{ scenarioId: string }>) =>
        useAuthenticatedMutation<{ scenarioId: string }, any, undefined>(
            ['manager.copyScenario'],
            {
                url: (params) => `/api/scenarios/${params.scenarioId}/copy`,
                method: 'post',
            },
            options,
        ),

    useDeleteSurvey: (options?: AuthenticatedMutationOptions<{ surveyId: string }>) =>
        useAuthenticatedMutation<{ surveyId: string }, any, undefined>(
            ['manager.deleteSurvey'],
            {
                url: (params) => `/api/surveys/${params.surveyId}`,
                method: 'delete',
            },
            options,
        ),
};
