import { useEffect } from 'react';
import { Grid, Paper, CssBaseline, Typography, LinearProgress } from '@mui/material';
import LoginBg from 'Assets/images/login-bg.jpg';
import { useMutation } from 'react-query';
import { linkDetails } from 'Features/participant/register/queries/mutations';
import { UiLocale, useLocale } from 'Src/localizations/i18n';
import { RegisterLinkWithPresetPasswordForm } from 'Features/participant/register/components/RegisterLinkWithPresetPasswordForm';
import RegisterLayout from 'Features/participant/register/components/shared/RegisterLayout';
import RegisterWithTokenForm from 'Features/participant/register/components/RegisterWithTokenForm';
import { useRoutingParams } from 'Src/shared/hooks';
import { useLocation } from 'react-router-dom';
import { WantentProvider } from 'Shared/components';
import { useAuth0 } from '@auth0/auth0-react';

interface RegisterLinkPathParams {
    t: string;
    preferredLocale: UiLocale;
}

const RegisterLink = () => {
    useLocation();
  const { t: id, preferredLocale } = useRoutingParams<RegisterLinkPathParams>();
    const { mutateAsync: getIsTokenUsed, data, isLoading, isError } = useMutation(linkDetails);
    const auth0 = useAuth0();
    useLocale(preferredLocale);
    useEffect(() => {
        if (id) {
            getIsTokenUsed({ body: { id: id } });
        }
    }, [getIsTokenUsed, id]);
    if (data?.isUsed && id) {
        auth0.loginWithRedirect({
            appState: {
                returnTo: '/me',
            },
            authorizationParams: {
                login_hint: data.email,
            },
        });
    }
    if (isLoading || data?.isUsed) {
        return (
            <WantentProvider>
                <RegisterLayout>
                    <LinearProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0, height: 2 }} />
                </RegisterLayout>
            </WantentProvider>
        );
    }
    if (!id || !data || isError) {
        return (
            <Grid
                container
                component="main"
                sx={{ background: `url(${LoginBg}) no-repeat fixed center center / cover` }}
                justifyContent="center"
                style={{ height: 'auto' }}
            >
                <CssBaseline />
                <Typography>Wrong token</Typography>
            </Grid>
        );
    }
    if (data.hasPresetPassword) {
        return (
            <WantentProvider>
                <RegisterLayout>
                    <RegisterLinkWithPresetPasswordForm focusGroupId={id} />
                </RegisterLayout>
            </WantentProvider>
        );
    }
    return (
        <Grid
            container
            component="main"
            sx={{
                background: `url(${LoginBg}) no-repeat fixed center center / cover`,
            }}
            justifyContent="flex-end"
            style={{ height: '100%' }}
        >
            <CssBaseline />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <RegisterWithTokenForm hasPresetPassword={data.hasPresetPassword} id={id} />
            </Grid>
        </Grid>
    );
};

export default RegisterLink;
