import { useRef } from 'react';
import * as React from 'react';
import { FormControl, FormHelperText, Button, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Done as DoneIcon, Close } from '@mui/icons-material';
import { FieldRenderProps } from 'react-final-form';

const Input = styled('input')({
    display: 'none',
    color: 'lightgray !important',
});

const Label = styled('label')({
    display: 'flex',
});
const Status = styled('div')({
    display: 'flex',
    padding: '5px',
});

const truncateMiddle = (str: string, maxLength: number) => {
    if (str.length <= maxLength) {
        return str;
    }
    const startLength = Math.floor(maxLength / 2) - 1;
    const endLength = Math.ceil(maxLength / 2) - 1;
    return `${str.slice(0, startLength)}...${str.slice(-endLength)}`;
};

const getPromiseFromEvent = (element: HTMLElement, event: string): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
        const listener = function (this: any) {
            resolve({ width: this.videoWidth, height: this.videoHeight });
        };
        element.addEventListener(event, listener);
    });
};

const getVideoFileDimensions = async (file: File) => {
    const url = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.src = url;
    return await getPromiseFromEvent(video, 'loadedmetadata');
};

const handleChange = (handler: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const isVideoFile = files ? files[0].type.includes('video') : false;
    files &&
        files.length &&
        isVideoFile &&
        getVideoFileDimensions(files[0]).then(({ width, height }) => {
            handler({ file: files[0], name: files[0].name, width, height });
        });
    handler(files && files.length ? { file: files[0], name: files[0].name } : undefined);
};

export const FileInput = (props: FieldRenderProps<any> & { label?: string; buttonLabel: string; id?: string }) => {
    const {
        input: { onChange, onBlur, value: omitValue, ...inputProps },
        label,
        meta: { touched, error },
        onFileRemove,
        ...custom
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    if (!custom.id) {
        custom.id = 'file-field';
    }

    const removeFile = () => {
        onFileRemove();
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    return (
        <FormControl
            margin="normal"
            required
            fullWidth
            error={touched && error}
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
            <Input
                {...inputProps}
                {...custom}
                type="file"
                multiple
                onChange={handleChange(onChange)}
                onBlur={handleChange(onBlur)}
                ref={inputRef}
            />
            <Label htmlFor={custom.id}>
                <Button
                    variant="outlined"
                    sx={{ color: custom.disabled ? 'rgba(0, 0, 0, 0.23)' : '#000' }}
                    component="span"
                    size="small"
                >
                    {label ? truncateMiddle(label, 20) : label}
                </Button>
                {omitValue && (
                    <Status>
                        <FormHelperText id={custom['aria-describedby']}>{omitValue.name}</FormHelperText>
                        <DoneIcon color="primary" />
                    </Status>
                )}
            </Label>
            {omitValue && typeof onFileRemove === 'function' && (
                <IconButton onClick={removeFile} size="large">
                    <Close />
                </IconButton>
            )}
            {custom['aria-describedby'] && <FormHelperText id={custom['aria-describedby']}>{error}</FormHelperText>}
        </FormControl>
    );
};

export const ImageInputWithPreview = (props: FieldRenderProps<any> & { label?: string; id?: string }) => {
    const {
        input: { onChange, onBlur, value: inputValue, ...inputProps },
        label,
        meta: { touched, error },
        onFileRemove,
        ...custom
    } = props;
    const previewBase64 = inputValue?.file ? URL.createObjectURL(inputValue.file) : inputValue;
    const inputRef = useRef<HTMLInputElement>(null);

    if (!custom.id) {
        custom.id = 'file-field';
    }

    const removeFile = () => {
        onFileRemove();
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    return (
        <FormControl
            margin="normal"
            required
            fullWidth
            error={touched && error}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
            <Box>
                {inputValue?.url || previewBase64 ? (
                    <img
                        src={inputValue?.url || previewBase64}
                        alt={inputValue?.name}
                        style={{ width: '100px', height: '100px' }}
                    />
                ) : (
                    <span>No image</span>
                )}
            </Box>
            <Input
                {...inputProps}
                {...custom}
                type="file"
                multiple
                onChange={handleChange(onChange)}
                onBlur={handleChange(onBlur)}
                ref={inputRef}
            />
            <Label htmlFor={custom.id}>
                {inputValue && (
                    <Status>
                        <FormHelperText id={custom['aria-describedby']}>
                            {inputValue.name ? truncateMiddle(inputValue.name, 20) : inputValue.name}
                        </FormHelperText>
                    </Status>
                )}

                <Box>
                    <Button
                        variant="outlined"
                        sx={{ color: custom.disabled ? 'rgba(0, 0, 0, 0.23)' : '#000' }}
                        component="span"
                        size="small"
                    >
                        {props.buttonLabel}
                    </Button>
                </Box>
            </Label>

            {inputValue && typeof onFileRemove === 'function' && (
                <Button onClick={removeFile} size="large">
                    Remove <Close />
                </Button>
            )}
            {custom['aria-describedby'] && <FormHelperText id={custom['aria-describedby']}>{error}</FormHelperText>}
        </FormControl>
    );
};
