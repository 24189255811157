import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import { QuestionQuota } from './EditQuestionQuota';
import { ScreenerQuestion } from 'Features/manager/shared/queries';

interface QuestionQuotasTableProps {
    quotas: QuestionQuota[];
    questions: ScreenerQuestion[];
    onEdit: (quota: QuestionQuota) => void;
    onDelete: (quota: QuestionQuota) => void;
}

const QuestionQuotasTable: React.FC<QuestionQuotasTableProps> = ({ quotas, onEdit, onDelete, questions }) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Question</TableCell>
                        <TableCell>Options</TableCell>
                        <TableCell>Edit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {quotas.map((quota, index) => (
                        <TableRow key={index}>
                            <TableCell>{questions.find((q) => q.id == quota.questionId)?.text}</TableCell>
                            <TableCell>
                                {quota.quotaRules.map((option) => `${option.text}: ${option.percentage}%`).join(', ')}
                            </TableCell>
                            <TableCell>
                                <Button onClick={() => onEdit(quota)}>Edit</Button>
                            </TableCell>
                            <TableCell>
                                <Button onClick={() => onDelete(quota)}>Delete</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default QuestionQuotasTable;
