import { useEffect } from 'react';
import * as React from 'react';

import { CustomVideoRecorder } from './reaction-recorders/CustomVideoRecorder';
import { YoutubeVideoRecorder } from './reaction-recorders/YoutubeVideoRecorder';
import { VideoType } from 'Shared/types';
import { useDispatch } from 'react-redux';
import { useActionButton } from 'Features/participant/workspace/scenario/hooks/useActionButton';

interface IRecorderProps {
    video: {
        videoForReactionId: string;
        requiredWatchedPercentage: number;
    };
    type: VideoType;
}

export const Recorder: React.FC<IRecorderProps> = ({ video, type }) => {
    const dispatch = useDispatch();
    const actionButton = useActionButton();
    useEffect(() => {
        actionButton.setDisabled(true);
    }, [video.videoForReactionId, dispatch]);

    switch (type) {
        case VideoType.videoContent:
        case VideoType.watermarkedContent:
            return <CustomVideoRecorder video={video} videoType={type} />;
        case VideoType.youtube:
            return <YoutubeVideoRecorder video={video} />;

        default:
            throw new Error('Unexpected video type');
    }
};
