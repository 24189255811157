import { useRef, useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CHECK_USER_PRESENCE_INTERVAL, dataPrefixLength } from 'Features/participant/workspace/scenario/constants';
import { Webcam } from 'Src/features/participant/workspace/scenario/classes/Webcam';
import {
    useObservable,
    videoEvent$,
    videoEventTypes,
} from 'Features/participant/workspace/scenario/observables/videoEvent';
import { participantMutations } from 'Features/participant/workspace/scenario/queries';
import { _base64ToArrayBuffer } from 'Src/shared/utils/toBase64';

const checkFaceStub = async (_base64screenshot: string) => {
    return {
        isFaceDetected: true,
    };
};

export const useCheckUserPresence = (webcamRef: { current: Webcam | null }, recorder: MediaRecorder | null) => {
    const { t: getLabel } = useTranslation();
    const faceDetectedFailedCounterRef = useRef(0);
    const { mutateAsync: checkFace } = participantMutations.useFaceCheck();
    const [userPresenceMsg, setUserPresenceMsg] = useState<string>();
    const { state } = useObservable(videoEvent$);

    const userPresenceIntervalId = useRef<ReturnType<typeof setInterval> | null>(null);

    const getIsFaceDetected = useCallback(
        async (base64screenshot: string) => {
            const { isFaceDetected } = await checkFace({ body: _base64ToArrayBuffer(base64screenshot) });
            if (!isFaceDetected) {
                if (faceDetectedFailedCounterRef.current < 3) {
                    faceDetectedFailedCounterRef.current++;
                } else {
                    faceDetectedFailedCounterRef.current = 0;
                }
            } else {
                faceDetectedFailedCounterRef.current = 0;
            }
            return {
                isFaceDetected,
            };
        },
        [checkFace],
    );

    const checkUserPresence = useCallback(async () => {
        const base64screenshot = webcamRef.current?.getScreenshot()?.slice(dataPrefixLength);
        if (!base64screenshot) {
            console.error(getLabel('participant.watch.webcamCheckFailed.screenshotFailed'));
            return;
        }

        // TODO: remove stub when backend is ready and tested
        const { isFaceDetected } = await checkFaceStub(base64screenshot);

        if (!isFaceDetected) {
            userPresenceIntervalId.current &&
                clearInterval(userPresenceIntervalId.current as ReturnType<typeof setInterval>);
            if (document.fullscreenElement) {
                if (faceDetectedFailedCounterRef.current === 3) {
                    recorder?.pause();
                    document.exitFullscreen();
                    setUserPresenceMsg(getLabel('participant.watch.faceNotDetected'));
                } else {
                    checkUserPresence();
                }
            } else {
                faceDetectedFailedCounterRef.current = 0;
            }
        }
    }, [getIsFaceDetected, recorder, getLabel, setUserPresenceMsg, userPresenceIntervalId, webcamRef]);

    const onVideoPlay = useCallback(() => {
        setUserPresenceMsg(undefined);
        const intervalId = setInterval(checkUserPresence, CHECK_USER_PRESENCE_INTERVAL);
        userPresenceIntervalId.current = intervalId;
    }, [checkUserPresence, setUserPresenceMsg]);

    const onVideoPause = useCallback(() => {
        userPresenceIntervalId.current && clearInterval(userPresenceIntervalId.current as any);
    }, [userPresenceIntervalId]);

    const handlers = useMemo(
        () => ({
            [videoEventTypes.onParticipantPlayerPlay]: onVideoPlay,
            [videoEventTypes.onParticipantPlayerPause]: onVideoPause,
        }),
        [onVideoPlay, onVideoPause],
    );

    useEffect(() => {
        if (state?.status) {
            const handler = handlers[state.status];
            handler instanceof Function && handler();
        }
    }, [state?.status, handlers]);

    useEffect(() => {
        return () => {
            userPresenceIntervalId.current && clearInterval(userPresenceIntervalId.current as any);
        };
    }, [onVideoPause, onVideoPlay, userPresenceIntervalId]);
    return userPresenceMsg;
};
