import { styled } from '@mui/material/styles';
import { useState, useCallback, useEffect } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { finishStep, getCurrentStep, getStepperIds } from 'Features/participant/workspace/scenario/slice';
import { Calibration } from '../Calibration';
import { ICalibrationRequestData } from '../Calibration';
import { sendWorker } from 'Src/main';
import { getRecorderReactionUploadName } from 'Features/participant/workspace/scenario/utils/recorder';
import { getMediaRecorder } from 'Features/participant/workspace/scenario/utils/recorder';
import { errorSnack, infoSnack } from 'Shared/components/snackbars/Snackbar';
import { chunkDurationSec } from 'Features/participant/workspace/scenario/constants';
import { useActionButton } from 'Features/participant/workspace/scenario/hooks/useActionButton';
import { useRecorder } from 'Features/participant/workspace/scenario/hooks';
import { participantMutations, participantQueries } from 'Features/participant/workspace/scenario/queries';
import { ScenarioPaper } from '../shared/ScenarioPaper';
import CalibrationInstruction from '../CalibrationInstruction';
import Info  from 'Assets/svg/info.svg?react';
import { Alert, Typography } from '@watched-tech/wantent-ui';

const CalibrationWrapper = styled('div')({
    width: '100%',
    overflow: 'hidden',
});

const TitleContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
}));
const StyledInfo = styled(Info)(({ theme }) => ({
    marginRight: theme.spacing(4),
    width: 24,
    height: 24,
}));

export const CalibrationRecorder: React.FC = () => {
    const dispatch = useDispatch();
    const currentStep = useSelector(getCurrentStep)?.scenarioStep;
    const stepperIds = useSelector(getStepperIds);
    const [screen] = useState({ height: window.screen.height, width: window.screen.width });
    const [interruptedTimestamp, setInterruptedTs] = useState<number | null>(null);
    const [stepId] = useState<string | undefined>(currentStep?.id);
    const [sessionId] = useState<string | undefined>(stepperIds?.stepSessionId);
    const [scenarioId] = useState<string | undefined>(stepperIds?.scenarioId);
    const { t: getLabel } = useTranslation();
    const { mutateAsync: finishStepSessionAsync } = participantMutations.useFinishScenarioStepSession();
    const actionButton = useActionButton();
    const [isInstructionOpen, setIsInstructionOpen] = useState(true);
    const [interruptedMsg, setInterruptedMsg] = useState('');
    const { isWebcamReady, errorMessage, setRecorder, setIsWebcamReady, recorder, stream, finishReactionRecording } =
        useRecorder();

    useEffect(() => {
        actionButton.setDisabled(true);
    }, []);

    const {
        isLoading,
        data: calibrationStep,
        error: getCalibrationStepError,
    } = participantQueries.useParticipantScenarioCalibrationStep(
        { scenarioId: scenarioId!, calibrationId: stepId! },
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    );

    useEffect(() => {
        if (interruptedTimestamp) {
            setInterruptedMsg(getLabel('participant.calibration.interrupt'));
        }
    }, [interruptedTimestamp, getLabel]);

    const { mutate: sendPoints } = participantMutations.useSendCalibrationPoints();

    const handleCalibrationStart = useCallback(() => {
        if (recorder?.state !== 'recording') recorder?.start(chunkDurationSec * 1000);
    }, [recorder]);

    const handleCalibrationInterrupted = useCallback(() => {
        setInterruptedTs(Date.now());
        if (recorder?.ondataavailable && stepId && stream) {
            recorder.ondataavailable = null;
            const { recorder: newRecorder, isWebcamReady } = getMediaRecorder(stream, (event) =>
                handleWebcamDataAvailable(stepId, event),
            );
            setRecorder(newRecorder);
            setIsWebcamReady(isWebcamReady);
        }
    }, [recorder, stepId, stream, setRecorder, setIsWebcamReady]);

    const handleCalibrationEnd = useCallback(
        async (data: ICalibrationRequestData[], screen: { height: number; width: number }) => {
            if (!stepId) throw new Error('Step id cannot be undefined when finishing calibration');
            finishReactionRecording(stepId);
            sendPoints({
                urlParams: { id: sessionId! },
                body: {
                    calibrationPoints: data,
                    screenWidth: Math.ceil(screen.width),
                    screenHeight: Math.ceil(screen.height),
                },
            });
            dispatch(finishStep());
            sessionId && (await finishStepSessionAsync({ urlParams: { id: sessionId } }));
        },
        [finishReactionRecording, sendPoints, sessionId, stepId, finishStepSessionAsync, dispatch],
    );

    const handleWebcamDataAvailable = async (stepId: string, event: BlobEvent) => {
        if (event.data.size <= 0) {
            return;
        }
        await sendWorker.recordBlob(getRecorderReactionUploadName(stepId), event.data);
    };
    const openInstruction = useCallback(() => {
        setIsInstructionOpen(true);
    }, []);
    const title = (
        <TitleContainer>
            <span data-testid="calibration-paper-title">{getLabel('participant.calibration.title')}</span>
            <Typography variant="b2" color="text.secondary" onClick={openInstruction}>
                <StyledInfo />
                Instruction to calibration
            </Typography>
        </TitleContainer>
    );
    return (
        <ScenarioPaper
            title={title}
            alert={interruptedMsg ? <Alert type="warning" text={interruptedMsg} /> : undefined}
        >
            {!isWebcamReady && infoSnack(getLabel('participant.watch.acceptwebcam'))}
            {errorMessage && errorSnack(errorMessage)}
            {isWebcamReady && !errorMessage && !isLoading && !getCalibrationStepError && calibrationStep && (
                <CalibrationWrapper>
                    <CalibrationInstruction open={isInstructionOpen} setOpen={setIsInstructionOpen} />
                    <Calibration
                        key={interruptedTimestamp}
                        screen={screen}
                        onCalibrationStart={handleCalibrationStart}
                        onCalibrationInterrupted={handleCalibrationInterrupted}
                        onCalibrationEnd={handleCalibrationEnd}
                        isAdaptive={calibrationStep.isAdaptive}
                        nextVideoWidth={calibrationStep.nextVideo?.width}
                        nextVideoHeight={calibrationStep.nextVideo?.height}
                    />
                </CalibrationWrapper>
            )}
        </ScenarioPaper>
    );
};

export default CalibrationRecorder;
