import { useState, createContext } from 'react';
import * as React from 'react';

export const VirtualKeyboardContext = createContext<any>(undefined);

export function withVirtualKeyboardCtx<Params>(Component: React.FC<Params> | React.ComponentClass<Params>) {
    return function WithKeyboardComponent(props: any) {
        return (
            <VirtualKeyboardProvider>
                <Component {...props} />
            </VirtualKeyboardProvider>
        );
    };
}

export const VirtualKeyboardProvider = ({ children }: any) => {
    const [isKeyboardShown, setIsKeyboardShown] = useState<boolean>(false);

    return (
        <VirtualKeyboardContext.Provider value={{ isKeyboardShown, setIsKeyboardShown }}>
            {children}
        </VirtualKeyboardContext.Provider>
    );
};
