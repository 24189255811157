import { useNavigate } from 'react-router-dom';
import { useCallback, FC } from 'react';
import * as React from 'react';
import { Card } from '@watched-tech/wantent-ui';
import { IScenario } from 'Features/participant/workspace/types';

interface IScenarioCardProps {
    scenario: IScenario;
    Chip: React.ElementType;
    clickable: boolean;
    testId?: string;
    dataAttrs?: Record<string, string>;
}

export const ScenarioCard: FC<IScenarioCardProps> = ({ scenario, clickable, Chip, dataAttrs }) => {
    const navigate = useNavigate();
    const scenarioClickHandler = useCallback(() => {
        navigate(`scenario/${scenario.id}/instructions`);
    }, [navigate, scenario.id]);
    return (
        <Card
            id={scenario.id}
            dataAttrs={dataAttrs}
            onClick={clickable ? scenarioClickHandler : undefined}
            Tag={() => null}
            title={scenario.name}
            date=""
            Chip={Chip}
            coverImage={scenario.imageLink}
            coverBg={'#b5b7ca'}
        />
    );
};
