import { UseQueryOptions } from 'react-query/types/react/types';
import { FocusGroup, FocusGroupDetails, IRegisterLink, ISurvey } from 'Features/manager/shared/types';
import { addDomainName } from 'Utils/global';
import {
    AudienceBucketDetails,
    IAIReportDetails,
    IManagerScenario,
    IManagerScenarioDetails,
    IManagerScenarioDetailsStatus,
    ISurveyDetails,
} from 'Pages/manager/scenarios/types';
import User from 'Shared/models/User';
import { DataCollectionMethod } from 'Pages/manager/scenarios/types/DataCollectionMethod';
import { EditScenarioStep } from 'Pages/manager/scenarios/ManagerEditScenarioPage/slice';
import { useAuthenticatedQuery } from 'Shared/hooks/queries';
import { ElementType, QuestionOption } from 'Shared/types';
import { ScreenerModel } from 'Features/manager/shared/queries/mutations';

export interface IQueryCallbackId {
    id: string;
}
export interface ScenarioSurveyQuestion {
    text: string;
    id: string;
    elementType: ElementType;
    position: number;
    stepPosition: number;
}

export interface ScenarioSurveyQuestion {
    text: string;
    id: string;
    type: ElementType;
    position: number;
    options?: QuestionOption[];
}

interface ScenarioQuestions {
    screenerQuestions: ScenarioSurveyQuestion[];
    surveys: SurveyQuestions[];
}

interface SurveyQuestions {
    title: string;
    stepPosition: number;
    surveyElements: ScenarioSurveyQuestion[];
}

export interface ScenarioSurveyQuestion {
    text: string;
    id: string;
    elementType: ElementType;
    position: number;
    stepPosition: number;
}

export const managerQueries = {
    useRegisterLinks: (focusGroupId: string, options: UseQueryOptions<IRegisterLink[]>) =>
        useAuthenticatedQuery<IRegisterLink[]>(
            ['manager.registerLinks', { id: focusGroupId }],
            {
                url: (params: any) => `/api/focusgroups/${params.queryKey[1]?.id}/register_links`,
                dataMapper: (response) => {
                    return response?.map((registerLinkItem: IRegisterLink, idx: number) => {
                        return {
                            ...registerLinkItem,
                            idx: idx + 1,
                            link: addDomainName(registerLinkItem.link),
                        };
                    });
                },
            },
            options,
        ),
    useSurveys: (options: UseQueryOptions<ISurvey[]>) =>
        useAuthenticatedQuery<ISurvey[]>(
            ['manager.surveys'],
            {
                url: `/api/surveys`,
            },
            options,
        ),
    useFocusGroups: (options: UseQueryOptions<FocusGroup[]>) =>
        useAuthenticatedQuery<FocusGroup[]>(
            ['manager.focusGroups'],
            {
                url: `/api/focusgroups`,
            },
            options,
        ),
    useScenarios: (options: UseQueryOptions<IManagerScenario[]>) =>
        useAuthenticatedQuery<IManagerScenario[]>(
            ['manager.scenarios'],
            {
                url: '/api/scenarios',
            },
            options,
        ),
    useScenario: (id: string, options: UseQueryOptions<IManagerScenarioDetails>) =>
        useAuthenticatedQuery<IManagerScenarioDetails>(
            ['manager.scenarios', { id }],
            { url: (params: any) => `/api/scenarios/${params.queryKey[1]?.id}` },
            options,
        ),
    useAudienceBucket(id: string, options?: UseQueryOptions<AudienceBucketDetails, Error, AudienceBucketDetails>) {
        return useAuthenticatedQuery<AudienceBucketDetails, AudienceBucketDetails, Error>(
            ['manager.audienceBucket', { id }],
            {
                url: (params: any) => `/api/v2/audiencebuckets/${params.queryKey[1]?.id}`,
            },
            options,
        );
    },
    useScreener(bucketId: string, options?: UseQueryOptions<ScreenerModel>) {
        return useAuthenticatedQuery<ScreenerModel>(
            ['manager.screener', { bucketId }],
            {
                url: (params: any) => `/api/v2/audiencebuckets/${params.queryKey[1]?.bucketId}/screener`,
            },
            options,
        );
    },
    useSurvey: (id: string, options: UseQueryOptions<ISurveyDetails>) =>
        useAuthenticatedQuery<ISurveyDetails>(
            ['manager.survey', { id }],
            { url: (params: any) => `/api/surveys/${params.queryKey[1]?.id}` },
            options,
        ),
    useReports: (id: string, stepId: string, options: UseQueryOptions<IAIReportDetails[], Error>) =>
        useAuthenticatedQuery<IAIReportDetails[], IAIReportDetails[], Error>(
            ['manager.reports', { id, stepId }],
            {
                url: (params: any) =>
                    `/api/scenarios/${params.queryKey[1]?.id}/ai_reports?stepId=${params.queryKey[1]?.stepId}`,
            },
            options,
        ),
    useScenarioStatus: (scenarioId: string, options: UseQueryOptions<IManagerScenarioDetailsStatus>) =>
        useAuthenticatedQuery<IManagerScenarioDetailsStatus>(
            ['manager.scenarioStatus', { id: scenarioId }],
            {
                url: (params: any) => `/api/Scenarios/${params.queryKey[1]?.id}/status`,
            },
            options,
        ),
    useManagerFocusGroupDetails: (focusGroupId: string, options?: UseQueryOptions<FocusGroupDetails>) =>
        useAuthenticatedQuery<FocusGroupDetails>(
            ['managerFocusGroupDetails', { id: focusGroupId }],
            {
                url: (params: any) => `/api/focusgroups/${params.queryKey[1]?.id}`,
                dataMapper: (response) => {
                    const participants = response.participants.map(User.fromJsonObject);
                    return {
                        ...response,
                        participants,
                    };
                },
            },
            options,
        ),
    useManagers: (options?: UseQueryOptions<any>) =>
        useAuthenticatedQuery<any>(['admin.getManagers'], { url: '/api/users/app_users' }, options),
    useSessionThumbnail: (id: string, options?: UseQueryOptions<string>) =>
        useAuthenticatedQuery<string>(
            ['manager.getSessionThumbnail', { id }],
            {
                url: (params: any) => `/api/scenariostepsessions/${params.queryKey[1]?.id}/thumbnail_url`,
            },
            options,
        ),
    useDCMs: (scenarioId: string, options?: UseQueryOptions<DataCollectionMethod[]>) =>
        useAuthenticatedQuery<DataCollectionMethod[]>(
            ['manager.getDCMs', { scenarioId }],
            {
                url: (params: any) => `/api/scenarios/${params.queryKey[1]?.scenarioId}/dcms`,
            },
            options,
        ),
    useScenarioSteps: (scenarioId: string, options?: UseQueryOptions<EditScenarioStep[], Error>) =>
        useAuthenticatedQuery<EditScenarioStep[], EditScenarioStep[], Error>(
            ['manager.getScenarioSteps', { scenarioId }],
            {
                url: (params: any) => `/api/scenarios/${params.queryKey[1]?.scenarioId}/steps`,
            },
            options,
        ),
    useGetScenarioSurveyQuestions: (scenarioId: string, options?: UseQueryOptions<ScenarioQuestions>) =>
        useAuthenticatedQuery<ScenarioQuestions>(
            ['manager.getScenarioSurveyQuestions', { scenarioId }],
            {
                url: (params: any) => `/api/scenarios/${params.queryKey[1]?.scenarioId}/survey_questions`,
            },
            options,
        ),
};
