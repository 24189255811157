import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActionButton, setActionButton } from 'Features/participant/workspace/scenario/slice';

export const useActionButton = () => {
    const actionButton = useSelector(getActionButton);

    const onClick = useCallback(() => {
        typeof actionButton.onClick === 'function' && actionButton.onClick();
    }, [actionButton]);
    const dispatch = useDispatch();

    const setOnClick = useCallback(
        (handler?: (props?: any) => void) => {
            dispatch(
                setActionButton({
                    onClick: handler,
                }),
            );
        },
        [dispatch],
    );

    const setDisabled = useCallback(
        (disabled: boolean) => {
            dispatch(
                setActionButton({
                    disabled,
                }),
            );
        },
        [dispatch],
    );

    return {
        onClick,
        disabled: actionButton.disabled,
        label: actionButton.label,
        setOnClick,
        setDisabled,
    };
};
