import { FC, SyntheticEvent, useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Textarea } from '@watched-tech/wantent-ui';
import { SxProps, Theme } from '@mui/material';
import { DataAttrs } from '@watched-tech/wantent-ui/utils';

interface IParticipantFinalFormTextareaProps {
    placeholder?: string;
    disabled?: boolean;
    label?: string;
    helperText?: string;
    ErrorIcon?: any;
    isActive: boolean;
    onFocus?: (e: SyntheticEvent) => void;
    onBlur?: (e: SyntheticEvent) => void;
    sx?: {
        wrapper?: SxProps<Theme>;
        label?: SxProps<Theme>;
        textarea?: SxProps<Theme>;
        iconWrapper?: SxProps<Theme>;
        error?: SxProps<Theme>;
        helperText?: SxProps<Theme>;
    };
    dataAttrs?: DataAttrs;
}

export const ParticipantFinalFormTextarea: FC<FieldRenderProps<string> & IParticipantFinalFormTextareaProps> = ({
    input,
    meta,
    id,
    placeholder,
    disabled,
    label,
    helperText,
    ErrorIcon,
    sx,
    onFocus,
    onBlur,
    dataAttrs,
}) => {
    const onTextareaFocus = useCallback(
        (e: any) => {
            input.onFocus(e);
            typeof onFocus === 'function' && onFocus(e);
        },
        [onFocus, input],
    );
    const onTextareaBlur = useCallback(
        (e: any) => {
            input.onBlur(e);
            typeof onBlur === 'function' && onBlur(e);
        },
        [input, onBlur],
    );
    return (
        <Textarea
            value={input.value}
            onChange={input.onChange}
            onBlur={onTextareaBlur}
            onFocus={onTextareaFocus}
            id={id}
            error={meta.touched && meta.error ? meta.error : undefined}
            placeholder={placeholder}
            disabled={disabled}
            label={label}
            helperText={helperText}
            ErrorIcon={ErrorIcon}
            sx={sx}
            dataAttrs={dataAttrs}
        ></Textarea>
    );
};
