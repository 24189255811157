import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import findLastIndex from 'lodash/findLastIndex';
import { CircularProgress, Sidebar, useWantentTheme } from '@watched-tech/wantent-ui';
import { LinearProgress, useMediaQuery, CircularProgress as MuiCircularProgress } from '@mui/material';
import { getMobileView, getParticipantError } from 'Features/participant/slice';
import { ParticipantScenarioErrorFallback } from 'Features/participant/workspace/scenario/components/ParticipantScenarioErrorFallback';
import { ISidebarStep, selectStepperState, setSidebarSteps, startScenarioSessionCreation } from 'Features/participant/workspace/scenario/slice';
import { IScenarioResponse, StepType } from 'Features/participant/workspace/scenario/types';
import { WebcamProvider } from 'Features/participant/workspace/scenario/context/webcamContext/WebcamProvider';
import ScenarioLayout from 'Features/participant/workspace/scenario/components/shared/layouts/ScenarioLayout';
import { VirtualKeyboardProvider } from 'Features/participant/workspace/scenario/context/virtualKeyboardContext/VirtualKeyboardProvider';
import { WrongDevice, ScenarioStepView, ReduxErrorBoundary } from 'Features/participant/workspace/scenario/components';
import { participantMutations } from 'Features/participant/workspace/scenario/queries/mutations';
import {
    setIds,
    reset as resetStepper,
    StepperStatus,
    getSurveyStep,
    getSurveyErrorsCount,
} from 'Features/participant/workspace/scenario/slice';
import { sendWorker } from 'Src/main';
import { FlexWrapper } from 'Features/participant/workspace/scenario/styles';
import { SidebarWrapper } from 'Features/participant/workspace/scenario/styles';
import OnlyHeaderLayout from 'Features/participant/shared/components/layouts/OnlyHeaderLayout';
import { Screener } from 'Pages/participant/workspace/scenario/Screener';
import { useRequiredParams } from 'Src/shared/hooks';
import { handleFollowingAction } from 'Utils/following-actions';
import { participantQueries } from 'Src/features/participant/workspace/scenario/queries';

type SidebarStepType = StepType & ('reactionUpload' | 'website' | 'feedback' | 'music' | 'screener');

const desktopScenarioStepTypes = [
    StepType.calibration,
    StepType.landing,
    StepType.presentation,
    StepType.video,
    StepType.website,
];

function isChromeBrowser() {
    // Get the user-agent string
    const userAgentString = navigator.userAgent;

    // Detect Chrome
    let chromeAgent = userAgentString.indexOf('Chrome') > -1;
    // Detect Opera
    const operaAgent = userAgentString.indexOf('OP') > -1;

    // Discard Chrome since it also matches Opera
    if (chromeAgent && operaAgent) chromeAgent = false;
    return chromeAgent;
}

function constructSidebarSteps(scenario: IScenarioResponse) {
    const steps: ISidebarStep[] = [];
    let preScenarioStepsCount = 0;
    let finishStepsCount = 0;
    if (scenario.screenerQuestions.length > 0) {
        steps.push({ type: 'screener' as SidebarStepType, id: steps.length + 1 });
        preScenarioStepsCount += 1;
    }

    scenario.steps.forEach((step) => {
        steps.push({ type: step.stepType as SidebarStepType, id: steps.length + 1, scenarioStep: step });
    });

    steps.push({ type: 'reactionUpload' as SidebarStepType, id: steps.length + 1 });
    steps.push({ type: 'feedback' as SidebarStepType, id: steps.length + 1 });
    finishStepsCount += 2;
    return { sidebarSteps: steps, preScenarioStepsCount, finishStepsCount };
}

function getStepToContinue(fetchedScenario: IScenarioResponse, preScenarioStepsCount: number) {
    const latestStepDone = fetchedScenario.steps.find((s: any) => s.id === fetchedScenario.lastStepDoneId);
    if (!latestStepDone) {
        throw new Error('Step with given id was not found');
    }
    const stepIdxToSet = fetchedScenario.lastStepSessionCompleted
        ? fetchedScenario.steps.indexOf(latestStepDone) + 1 + preScenarioStepsCount
        : fetchedScenario.steps.indexOf(latestStepDone) + preScenarioStepsCount;

    // all steps are completed but scenario is not finished
    const isScenarioCompleted = stepIdxToSet === fetchedScenario.steps.length + preScenarioStepsCount;
    return { stepIdxToSet, isScenarioCompleted };
}

const useParticipantSession = (scenarioId: string) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { mutateAsync: postScenarioSession } = participantMutations.usePostScenarioSession();
    const { mutateAsync: postStepSession } = participantMutations.usePostStepSession();
    const { mutateAsync: postLeaveScenario } = participantMutations.usePostLeaveScenario();
    const stepperState = useSelector(selectStepperState);
    useEffect(() => {
        const onBeforeUnload = (ev: BeforeUnloadEvent) => {
            if (!stepperState.currentStep?.id || !stepperState.ids?.scenarioSessionId) {
                return;
            }
            postLeaveScenario({
                urlParams: { id: stepperState.ids?.scenarioSessionId },
                body: { state: stepperState },
            });
            ev.returnValue = 'Are you sure you want to leave?';
            return 'Are you sure you want to leave?';
        };

        window.addEventListener('beforeunload', onBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, [stepperState.currentStep?.id, stepperState.ids?.scenarioSessionId]);
    const { isLoading: isScenarioLoading, error: getScenarioError } = participantQueries.useParticipantScenario(
        scenarioId,
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            onSuccess: async (fetchedScenario: IScenarioResponse) => {
                let sidebarStepIdx = 0;
                let initScenarioSessionId = '';
                let initStepSessionId = '';
                const hasWebsiteStep = fetchedScenario.steps.findIndex((s) => s.stepType === 'website') >= 0;
                if (hasWebsiteStep && !isChromeBrowser()) {
                    navigate('/me/require-chrome');
                }
                const { sidebarSteps, preScenarioStepsCount } =
                    constructSidebarSteps(fetchedScenario);
                if (!fetchedScenario.lastScenarioSessionId) {
                    dispatch(startScenarioSessionCreation());
                    const response = await postScenarioSession({ body: { scenarioId } });
                    if (response.followingAction.actionType !== 'doNothing') {
                        navigate(handleFollowingAction(response.followingAction));
                    }
                    initScenarioSessionId = response.session.id;
                } else {
                    initScenarioSessionId = fetchedScenario.lastScenarioSessionId;
                }
                if (fetchedScenario.isScreenerCompleted) {
                    sidebarStepIdx += 1;
                }
                if (fetchedScenario.lastStepDoneId) {
                    const { stepIdxToSet, isScenarioCompleted } = getStepToContinue(
                        fetchedScenario,
                        preScenarioStepsCount,
                    );
                    if (isScenarioCompleted) {
                        const curStep = sidebarSteps[stepIdxToSet];
                        dispatch(
                            setIds({
                                currentStep: curStep,
                                currentStepIdx: sidebarStepIdx,
                                scenario: fetchedScenario,
                                ids: {
                                    stepSessionId: initStepSessionId,
                                    scenarioSessionId: initScenarioSessionId,
                                    scenarioId,
                                },
                            }),
                        );
                        navigate(`/me/feedback?scenarioId=${fetchedScenario.id}`);
                        return;
                    }
                    sidebarStepIdx = stepIdxToSet;
                }
                if (sidebarStepIdx + 1 <= preScenarioStepsCount) {
                    const curStep = sidebarSteps[sidebarStepIdx];
                    dispatch(
                        setIds({
                            currentStep: curStep,
                            currentStepIdx: sidebarStepIdx,
                            scenario: fetchedScenario,
                            ids: {
                                stepSessionId: initStepSessionId,
                                scenarioSessionId: initScenarioSessionId,
                                scenarioId,
                            },
                        }),
                    );
                    dispatch(setSidebarSteps({ sidebarSteps, preScenarioStepsCount }));
                    return;
                }
                // participant started scenario but was interrupted, and has not completed step session
                if (fetchedScenario.lastStepSessionId && !fetchedScenario.lastStepSessionCompleted) {
                    initStepSessionId = fetchedScenario.lastStepSessionId;
                    // participant started scenario but was interrupted, and has completed step session
                } else {
                    if (fetchedScenario.lastStepSessionId && fetchedScenario.lastStepSessionCompleted)
                        sidebarStepIdx += 1;
                    const scenarioStep = sidebarSteps[sidebarStepIdx].scenarioStep!;
                    const { id } = await postStepSession({
                        body: {
                            scenarioSessionId: initScenarioSessionId,
                            scenarioStepId: scenarioStep.id,
                            stepType: scenarioStep.stepType,
                        },
                    });
                    initStepSessionId = id;
                }
                const curStep = sidebarSteps[sidebarStepIdx];
                dispatch(
                    setIds({
                        currentStep: curStep,
                        currentStepIdx: sidebarStepIdx,
                        scenario: fetchedScenario,
                        ids: {
                            stepSessionId: initStepSessionId,
                            scenarioSessionId: initScenarioSessionId,
                            scenarioId,
                        },
                    }),
                );
                dispatch(setSidebarSteps({ sidebarSteps, preScenarioStepsCount }));
            },
        },
    );

    return { isScenarioLoading, getScenarioError };
};

export function ParticipantScenario() {
    const { scenarioId } = useRequiredParams<{ scenarioId: string }>();
    const error = useSelector(getParticipantError);
    const dispatch = useDispatch();
    const stepperState = useSelector(selectStepperState);
    useParticipantSession(scenarioId);

    const steps = useMemo(() => {
        if (!stepperState.scenario) {
            return { sidebarSteps: [], preScenarioStepsCount: 0, finishStepsCount: 0 };
        }

        return constructSidebarSteps(stepperState.scenario);
    }, [stepperState.scenario]);
    const navigate = useNavigate();
    const { mutateAsync: postStepSession } = participantMutations.usePostStepSession();

    useEffect(() => {
        dispatch(resetStepper());
        sendWorker.resetUploads();
    }, [dispatch]);

    useEffect(() => {
        if (
            stepperState.currentStepIdx !== undefined &&
            stepperState.ids &&
            stepperState.scenario &&
            stepperState.status === StepperStatus.nextStep
        ) {
            const lastStepIndex = findLastIndex(stepperState.sidebarSteps, (step) => step.scenarioStep !== undefined);
            if (stepperState.currentStepIdx > lastStepIndex) {
                navigate('/me/finish-uploads');
                return;
            }
            if (!stepperState.currentStepIdx) {
                return;
            }
            const scenarioStep = stepperState.sidebarSteps[stepperState.currentStepIdx].scenarioStep;
            if (scenarioStep) {
                postStepSession({
                    body: {
                        scenarioSessionId: stepperState.ids.scenarioSessionId,
                        scenarioStepId: scenarioStep.id,
                        stepType: scenarioStep.stepType,
                    },
                }).then(({ id }) => {
                    if (stepperState.currentStepIdx && stepperState.ids && stepperState.scenario) {
                        dispatch(
                            setIds({
                                currentStep: stepperState.sidebarSteps[stepperState.currentStepIdx],
                                currentStepIdx: stepperState.currentStepIdx,
                                scenario: stepperState.scenario,
                                ids: {
                                    stepSessionId: id,
                                    scenarioSessionId: stepperState.ids.scenarioSessionId,
                                    scenarioId: stepperState.ids.scenarioId,
                                },
                            }),
                        );
                    }
                });
            }
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [stepperState.currentStepIdx]);

    useEffect(() => {
        steps &&
            dispatch(
                setSidebarSteps({
                    sidebarSteps: steps.sidebarSteps,
                    preScenarioStepsCount: steps.preScenarioStepsCount,
                }),
            );
    }, [steps, dispatch]);

    const hasDesktopStepTypes = useMemo(
        () => stepperState.scenario?.steps.some(({ stepType }) => desktopScenarioStepTypes.includes(stepType)),
        [stepperState.scenario?.steps],
    );

    const someWebcamCheckRequired = useMemo(
        () => stepperState.scenario?.steps.some(({ isWebcamCheckRequired }) => isWebcamCheckRequired),
        [stepperState.scenario?.steps],
    );
    const someRecordingRequired = useMemo(
        () => stepperState.scenario?.steps.some(({ isRecordingRequired }) => isRecordingRequired),
        [stepperState.scenario?.steps],
    );

    const { t: getLabel } = useTranslation();
    const step = useSelector(getSurveyStep);
    const surveyErrorsCount = useSelector(getSurveyErrorsCount);
    const { breakpoints } = useWantentTheme();
    const matchesLg = useMediaQuery(breakpoints.down('lg'));
    const showMobileView = useSelector(getMobileView);

    const allProgressBarSteps = useMemo(
        () => (step ? step.filter((step: any) => step.elementType !== 'info') : []),
        [step],
    );
    if ((isMobile && hasDesktopStepTypes === undefined) || (isMobile && someWebcamCheckRequired === undefined)) {
        return (
            <OnlyHeaderLayout>
                <MuiCircularProgress
                    sx={{
                        position: 'absolute',
                        top: 112,
                        right: 0,
                        left: 0,
                        margin: 'auto',
                        transform: 'translateX(-20px)',
                    }}
                />
            </OnlyHeaderLayout>
        );
    }

    if (
        (isMobile && hasDesktopStepTypes) ||
        (isMobile && someWebcamCheckRequired) ||
        (isMobile && someRecordingRequired)
    ) {
        return (
            <ReduxErrorBoundary Fallback={ParticipantScenarioErrorFallback} error={error}>
                <VirtualKeyboardProvider>
                    <WrongDevice />
                </VirtualKeyboardProvider>
            </ReduxErrorBoundary>
        );
    }

    return (
        <ReduxErrorBoundary Fallback={ParticipantScenarioErrorFallback} error={error}>
            <VirtualKeyboardProvider>
                <ScenarioLayout>
                    <SidebarWrapper>
                        {stepperState.scenario && stepperState.currentStepIdx !== undefined && (
                            <Sidebar
                                steps={steps.sidebarSteps}
                                activeId={stepperState.currentStepIdx! + 1}
                                sx={{
                                    root: {
                                        display: showMobileView ? 'none' : 'block',
                                    },
                                }}
                            />
                        )}
                    </SidebarWrapper>
                    <WebcamProvider>
                        <FlexWrapper>
                            {stepperState.currentStep &&
                                stepperState.currentStep.scenarioStep &&
                                stepperState.currentStep.scenarioStep.stepType === StepType.survey &&
                                allProgressBarSteps.length > 0 && (
                                    <CircularProgress
                                        left={matchesLg ? 40 : 132}
                                        questionsCount={allProgressBarSteps.length}
                                        questionsDone={allProgressBarSteps.length - surveyErrorsCount}
                                        isFinished={!surveyErrorsCount}
                                        text={[
                                            getLabel('participant.survey.questions'),
                                            getLabel('participant.survey.done'),
                                        ]}
                                        sx={{
                                            wrapper: {
                                                top: '32px',
                                                display: showMobileView ? 'none' : 'inline-grid',
                                            },
                                        }}
                                    />
                                )}
                            {stepperState.status !== StepperStatus.ready ? (
                                <LinearProgress sx={{ position: 'fixed', top: 0, right: 0, left: 0, height: 2 }} />
                            ) : stepperState.currentStep && stepperState.currentStep.type === 'screener' ? (
                                <Screener scenario={stepperState.scenario!} />
                            ) : (
                                <ScenarioStepView currentStep={stepperState.currentStep?.scenarioStep as any} />
                            )}
                        </FlexWrapper>
                    </WebcamProvider>
                </ScenarioLayout>
            </VirtualKeyboardProvider>
        </ReduxErrorBoundary>
    );
}
