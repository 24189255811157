import { useEffect } from 'react';
import OnlyHeaderLayout from 'Features/participant/shared/components/layouts/OnlyHeaderLayout';
import { useAuthContext } from 'Shared/auth/context';
import { useNavigate } from 'react-router-dom';
import { ManagementRoles, ParticipantRoles } from 'Shared/auth/common';
import { LinearProgress } from '@mui/material';

export const LoginCallback = () => {
    const { updateScheme } = useAuthContext();


    useEffect(() => {
        updateScheme('auth0');
    }, [updateScheme]);
    return (
        <OnlyHeaderLayout>
            <LinearProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0, height: 2 }} />
        </OnlyHeaderLayout>
    );
};

export const LoginRoleRedirectCallback = () => {
    const { isLoading, role } = useAuthContext();
    const navigate = useNavigate();
    useEffect(() => {
        if (isLoading) return;
        if (ManagementRoles.includes(role as any)) {
            navigate('/manager/scenarios');
            return;
        }
        if (ParticipantRoles.includes(role as any)) {
            navigate('/me');
            return;
        }

        navigate('/');
    }, [isLoading, role]);
    return (
        <OnlyHeaderLayout>
            <LinearProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0, height: 2 }} />
        </OnlyHeaderLayout>
    );
};
