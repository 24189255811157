import * as Sentry from '@sentry/react';

export function getApiAbsoluteUrl(url: string) {
    return url.startsWith('/') ? import.meta.env.VITE_API_BASE_URL + url : url;
}

export function toQueryParams(...objects: any) {
    const queryParams = new URLSearchParams();
    for (const object of objects) {
        for (const filterName in object) {
            if (object.hasOwnProperty(filterName) && object[filterName]) {
                queryParams.append(filterName, object[filterName]);
            }
        }
    }

    return queryParams;
}

export const postForm = (url: string, additionalHeaders: object, form: FormData) => {
    const requestHeaders = {
        Accept: 'application/json',
    };
    if (additionalHeaders) {
        Object.assign(requestHeaders, additionalHeaders);
    }

    return post(requestHeaders, url, form);
};

export const postJson = (url: string, additionalHeaders?: object, json?: object) => {
    const requestHeaders = {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
    };

    if (additionalHeaders) {
        Object.assign(requestHeaders, additionalHeaders);
    }

    return post(requestHeaders, url, json ? JSON.stringify(json) : undefined);
};

export function post(requestHeaders: Record<string, string>, url: string, body: any) {
    return fetch(url, {
        method: 'POST',
        headers: requestHeaders,
        body,
    });
}

export function put(url: string, body: any, headers: Record<string, string>) {
    return fetch(url, {
        method: 'PUT',
        headers,
        body,
    });
}

function wait(delay: number) {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export const sendSentryFetchError = (error: Response) => {
    Sentry.captureException(error);
    return error;
};

export class FetchClient {
    constructor(
        private onMaxRetriesExceeded: (res?: Response) => void = () => {},
        private accessToken: string = '',
    ) {}

    fetch = async (
        url: string,
        options: { maxRetries: number } & RequestInit,
        retryCount = 0,
        delay = 1000,
        exponential = true,
    ) => {
        const response = await this._fetchWithRetries(url, options, retryCount, delay, exponential);
        if (response.ok) {
            return response;
        } else {
            throw response;
        }
    };

    apiFetch = async (
        url: string,
        options: { maxRetries: number } & RequestInit,
        retryCount = 0,
        delay = 1000,
        exponential = true,
    ) => {
        if (options.headers) {
            // @ts-ignore
            options.headers['Authorization'] = `Bearer ${this.accessToken}`;
        } else {
            options.headers = { Authorization: `Bearer ${this.accessToken}` };
        }
        return this.fetch(getApiAbsoluteUrl(url), options, retryCount, delay, exponential);
    };

    _fetchWithRetries = async (
        url: string,
        options: { maxRetries: number } & RequestInit,
        retryCount = 0,
        delay = 1000,
        exponential = true,
    ): Promise<Response> => {
        // split out the maxRetries option from the remaining
        // options (with a default of 3 retries)
        const { maxRetries = 3, ...remainingOptions } = options;
        const newDelay = exponential ? delay * 2 : delay;
        try {
            return await fetch(url, remainingOptions).then((res) => {
                if (res.ok) {
                    return res;
                } else {
                    if (res.status === 401 && !res.url.endsWith('login')) {
                        postMessage({
                            type: 'refreshToken',
                            requestConfig: JSON.stringify({}),
                        });
                    }

                    if (retryCount < maxRetries) {
                        return wait(delay).then(() =>
                            this._fetchWithRetries(url, options, retryCount + 1, newDelay, exponential),
                        );
                    }
                    // max retries exceeded
                    this.onMaxRetriesExceeded(res);
                    return res;
                }
            });
        } catch (error) {
            // if the retryCount has not been exceeded, call again
            if (retryCount < maxRetries) {
                return wait(delay).then(() =>
                    this._fetchWithRetries(url, options, retryCount + 1, newDelay, exponential),
                );
            }
            // max retries exceeded
            this.onMaxRetriesExceeded();
            throw error;
        }
    };

    updateAccessToken(accessToken: string) {
        this.accessToken = accessToken;
    }
}
