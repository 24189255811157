import { Component } from 'react';
import * as React from 'react';
import * as Sentry from '@sentry/react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IReduxErrorBoundaryProps extends WithTranslation {
    children: React.ReactNode;
    Fallback: React.ComponentType<{ message?: string }>;
    error: string;
}

interface IReduxErrorBoundaryState {
    beError: string | Error;
    feError: string | Error;
}

class ReduxErrorBoundaryComponent extends Component<IReduxErrorBoundaryProps, IReduxErrorBoundaryState> {
    constructor(props: IReduxErrorBoundaryProps) {
        super(props);
        this.state = {
            beError: '',
            feError: '',
        };
    }

    componentDidCatch(error: Error) {
        Sentry.captureException(error);
    }

    static getDerivedStateFromError(error: Error) {
        return { feError: error };
    }
    static getDerivedStateFromProps(props: any) {
        return {
            beError: props.error,
        };
    }

    public render() {
        const { children, Fallback } = this.props;
        const { beError, feError } = this.state;
        const isError = beError || feError;
        if (
            feError instanceof Error &&
            feError.message.toLowerCase() == 'NotReadableError: Could not start video source'.toLowerCase()
        ) {
            return <Fallback message={this.props.t('participant.error.couldNotGetCamera')} />;
        }

        return isError ? <Fallback /> : children;
    }
}

export const ReduxErrorBoundary = withTranslation(undefined, { withRef: true })(ReduxErrorBoundaryComponent);
