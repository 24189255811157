import { useState } from 'react';
import * as React from 'react';
import {
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Typography,
} from '@mui/material';
import ExpandableCard from 'Shared/components/expandable-card';

export interface AgeQuota {
    type: 'byAge';
    quotaRules: AgeQuotaRule[];
}

export interface AgeQuotaRule {
    minAge: number;
    maxAge: number;
    percentage: number;
}

interface EditAgeQuotaProps {
    onSave: (quota: AgeQuota) => void;
    initialQuota?: AgeQuota;
}

const EditAgeQuota: React.FC<EditAgeQuotaProps> = ({ onSave, initialQuota }) => {
    const [quotaRules, setQuotaRules] = useState<AgeQuotaRule[]>(initialQuota?.quotaRules || []);
    const [selectedQuota, setSelectedQuota] = useState<AgeQuotaRule>({ minAge: 0, maxAge: 0, percentage: 0 });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!selectedQuota) {
            return;
        }
        const updatedQuota = { ...selectedQuota, [event.target.name]: Number(event.target.value) };
        setSelectedQuota(updatedQuota);
    };

    const handleEdit = (quota: AgeQuotaRule) => {
        setSelectedQuota(quota);
    };

    const handleDelete = (quota: AgeQuotaRule) => {
        setQuotaRules(quotaRules.filter((q) => q !== quota));
    };

    const handleSubmit = () => {
        if (selectedQuota) {
            setQuotaRules([...quotaRules.filter((q) => q !== selectedQuota), selectedQuota]);
            setSelectedQuota({ minAge: 0, maxAge: 0, percentage: 0 });
        }
    };

    const handleSave = () => {
        onSave({ type: 'byAge', quotaRules });
    };

    return (
        <ExpandableCard title={<Typography variant="h6">Quota by age</Typography>}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Min Age</TableCell>
                            <TableCell>Max Age</TableCell>
                            <TableCell>Percentage</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quotaRules.map((quota, index) => (
                            <TableRow key={index}>
                                <TableCell>{quota.minAge}</TableCell>
                                <TableCell>{quota.maxAge}</TableCell>
                                <TableCell>{quota.percentage}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEdit(quota)}>Edit</Button>
                                    <Button onClick={() => handleDelete(quota)}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box p={1}>
                <TextField
                    name="minAge"
                    label="Min Age"
                    value={selectedQuota.minAge}
                    onChange={handleChange}
                    required
                    fullWidth
                />
            </Box>
            <Box p={1}>
                <TextField
                    name="maxAge"
                    label="Max Age"
                    value={selectedQuota.maxAge}
                    onChange={handleChange}
                    required
                    fullWidth
                />
            </Box>
            <Box p={1}>
                <TextField
                    name="percentage"
                    label="Percentage"
                    value={selectedQuota.percentage}
                    onChange={handleChange}
                    required
                    fullWidth
                />
            </Box>
            <Box p={1}>
                <Button onClick={handleSubmit} fullWidth>
                    Submit this rule
                </Button>
            </Box>
            <Box p={1}>
                <Button variant="contained" onClick={handleSave} fullWidth>
                    Save all
                </Button>
            </Box>
        </ExpandableCard>
    );
};

export default EditAgeQuota;
