import { UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';
import {
    getMutationCallback,
    getQueryCallback,
    IMutationCallbackProps,
    IMutationRequestParams,
    IQueryCallbackProps,
} from 'Utils/query';
import { AxiosError } from 'axios';
import { MutationFunction, MutationKey, QueryFunction, QueryKey } from 'react-query/types/core/types';
import { useAuthContext } from 'Shared/auth/context';
import { useLocation } from 'react-router-dom';
import { merge } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

export type AuthenticatedMutationOptions<
    TVariables = void,
    TQueryFnData = unknown,
    TData = TQueryFnData,
    TError = unknown,
    TContext = unknown,
> = Omit<
    UseMutationOptions<TData, TError, IMutationRequestParams<TVariables, TQueryFnData>, TContext>,
    'mutationKey' | 'mutationFn'
>;

export function useAuthenticatedMutation<
    TVariables = void,
    TQueryFnData = unknown,
    TData = TQueryFnData,
    TError = AxiosError,
    TContext = unknown,
>(
    mutationKey: MutationKey,
    mutationCfg: IMutationCallbackProps<TData>,
    options?: AuthenticatedMutationOptions<TVariables, TQueryFnData, TData, TError, TContext>,
): UseMutationResult<TData, TError, IMutationRequestParams<TVariables, TQueryFnData>, TContext> {
    const auth = useAuthContext();
    const location = useLocation();
    const fn: MutationFunction<TData, IMutationRequestParams<TVariables, TQueryFnData>> = async (params) => {
        let token;
        try {
            token = await auth.getToken();
        } catch (e: any) {
            if (e.error === 'login_required') {
                auth.login(location.pathname);
            }
            if (e.error === 'consent_required') {
                auth.login(location.pathname);
            }
            throw e;
        }
        const axiosCfg = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-AuthScheme': auth.scheme,
            },
        };
        return getMutationCallback({ ...mutationCfg, config: merge(mutationCfg.config, axiosCfg) })(params);
    };
    return useMutation(mutationKey, fn, options);
}

export type QueryOptions<TData, TError = unknown, TQueryKey extends QueryKey = QueryKey> = Omit<
    UseQueryOptions<TData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
>;

export function useAuthenticatedQuery<
    TQueryFnData = unknown,
    TData = TQueryFnData,
    TError = unknown,
    TQueryKey extends QueryKey = QueryKey,
>(
    queryKey: TQueryKey,
    queryCfg: IQueryCallbackProps<TData>,
    options?: QueryOptions<TData, TError, TQueryKey>,
): UseQueryResult<TData, TError> {
    const auth = useAuthContext();
    const auth0 = useAuth0();
    const location = useLocation();
    const fn: QueryFunction<TData, QueryKey> = async (params) => {
        const token = await auth.getToken();
        const axiosCfg = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-AuthScheme': auth.scheme as string,
            },
        };
        return (
            getQueryCallback({ ...queryCfg, config: { ...queryCfg.config, ...axiosCfg } })(params) as Promise<TData>
        ).catch((err) => {
            if (err.response?.status === 401) auth0.loginWithRedirect({ appState: { returnTo: location.pathname } });

            throw err;
        });
    };
    return useQuery(queryKey, fn, options);
}

export function useUnauthenticatedQuery<
    TQueryFnData = unknown,
    TData = TQueryFnData,
    TError = unknown,
    TQueryKey extends QueryKey = QueryKey,
>(
    queryKey: TQueryKey,
    queryCfg: IQueryCallbackProps<TData>,
    options?: Omit<UseQueryOptions<TData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
    const fn: QueryFunction<TData, QueryKey> = async (params) => {
        return getQueryCallback(queryCfg)(params) as Promise<TData>;
    };
    return useQuery(queryKey, fn, options);
}
