import * as React from 'react';
import SectionHeader from 'Features/manager/shared/components/SectionHeader';
import ManagerLayout from './ManagerLayout';

interface ILayoutProps {
    headerText: string;
    children?: React.ReactNode;
}

const ManagerLayoutWithoutFab: React.FunctionComponent<ILayoutProps> = (props) => {
    const { headerText } = props;

    return (
        <ManagerLayout>
            <SectionHeader style={{ marginBottom: '40px' }} text={headerText} />
            {props.children}
        </ManagerLayout>
    );
};

export default ManagerLayoutWithoutFab;
