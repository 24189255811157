import { Typography, useWantentTheme } from '@watched-tech/wantent-ui';
import { ReactNode } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getMobileView } from 'Features/participant/slice';
import { useWindowSize } from 'usehooks-ts';
import { FlexContainer, Div, Container, IconWrapper } from './styles';

interface IEmptyStateLayoutProps {
    Icon: React.ElementType;
    title: string | ReactNode;
    description: string | ReactNode;
}
export const EmptyStateLayout = ({ Icon, title, description }: IEmptyStateLayoutProps) => {
    const { spacing } = useWantentTheme();
    const showMobileView = useSelector(getMobileView);
    const { height } = useWindowSize();
    return (
        <Container isMobile={showMobileView} height={height}>
            <div />
            <FlexContainer>
                <IconWrapper>
                    <Icon />
                </IconWrapper>
                <Div>
                    <Typography variant="h5" color="text.primary" sx={{ marginBottom: spacing(2) }}>
                        {title}
                    </Typography>
                    <Typography variant="p1" color="text.primary">
                        {description}
                    </Typography>
                </Div>
            </FlexContainer>
            <div />
        </Container>
    );
};
