import { useState, useCallback, useEffect } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ParticipantSurveyPaper } from 'Features/participant/workspace/scenario/components/Survey/ParticipantSurveyPaper';
import { getCurrentStep } from 'Features/participant/workspace/scenario/slice';
import { chunkDurationSec } from 'Features/participant/workspace/scenario/constants';
import { useRecorder } from 'Features/participant/workspace/scenario/hooks';

export interface ISurveyStepProps {
    surveyId: string;
    sendText: string;
}

export const SurveyRecorder: React.FC<ISurveyStepProps> = ({ ...surveyProps }) => {
    const { t: getLabel } = useTranslation();
    const currentStep = useSelector(getCurrentStep)?.scenarioStep;
    const { isWebcamReady, errorMessage, recorder, finishReactionRecording } = useRecorder();
    const [stepId] = useState<string | undefined>(currentStep?.id);
    const [recorderError, setRecorderError] = useState<string>();
    const [recorderInfo, setRecorderInfo] = useState<string>();

    useEffect(() => {
        if (!isWebcamReady) {
            setRecorderInfo(getLabel('participant.watch.acceptwebcam'));
        } else {
            setRecorderInfo('');
        }
    }, [isWebcamReady, getLabel]);
    useEffect(() => {
        if (errorMessage) {
            setRecorderError(errorMessage);
        }
    }, [errorMessage]);
    const onFinish = useCallback(() => {
        if (!stepId) throw new Error('Step id cannot be undefined when finishing survey');
        finishReactionRecording(stepId);
    }, [finishReactionRecording, stepId]);

    useEffect(() => {
        recorder?.state === 'inactive' && recorder.start(chunkDurationSec * 1000);
    }, [recorder]);

    return (
        <>
            {isWebcamReady && !errorMessage && (
                <ParticipantSurveyPaper
                    recorderError={recorderError}
                    recorderInfo={recorderInfo}
                    {...surveyProps}
                    onFinished={onFinish}
                />
            )}
        </>
    );
};

export default SurveyRecorder;
