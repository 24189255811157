import { useTranslation } from 'react-i18next';
import { Modal } from '@watched-tech/wantent-ui';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { registerWithToken } from 'Features/participant/register/queries/mutations';
import { useNavigate } from 'react-router-dom';

import { REGISTRATION_MODAL_WIDTH } from 'Features/participant/register/constants';
import { DemographicsAndTOSForm } from 'Features/participant/register/components/shared/DemographicsAndTOSForm';
import { useSelector } from 'react-redux';
import { getMobileView } from 'Features/participant/slice';
import { removeAuthToken, signInWntUserWithTokens } from 'Shared/auth/schemes/wnt';
import { useAuthContext } from 'Shared/auth/context';

export const RegisterLinkWithPresetPasswordForm = ({ focusGroupId }: { focusGroupId: string }) => {
    const { t: getLabel } = useTranslation();
    const navigate = useNavigate();
    const showMobileView = useSelector(getMobileView);
    const auth = useAuthContext();
    const { mutateAsync } = useMutation(registerWithToken, {
        onSuccess: (tokens: { accessToken: string; refreshToken: string }) => {
            const newLocation = showMobileView ? '/me' : '/survey-disclaimer';
            signInWntUserWithTokens(tokens.accessToken, tokens.refreshToken, auth);
            navigate(newLocation);
        },
    });

    const handleRegisterFormSubmit = useCallback(
        (values: any) => {
            removeAuthToken();
            const formData = new FormData();
            const { sex, age } = values;
            formData.append('sex', sex);
            formData.append('age', age);
            formData.append('id', focusGroupId);
            return mutateAsync({
                body: formData,
            });
        },
        [mutateAsync, focusGroupId],
    );

    return (
        <Modal
            open
            onClose={() => { }}
            title={getLabel('register.heading')}
            // subTitle={getLabel('register.subtitle')}
            width={REGISTRATION_MODAL_WIDTH}
            withCloseIcon={false}
            scroll="paper"
            sx={{
                modal: {
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'transparent',
                        backdropFilter: 'none',
                    },
                },
            }}
        >
            <DemographicsAndTOSForm onSubmit={handleRegisterFormSubmit} initialValues={{}} />
        </Modal>
    );
};
