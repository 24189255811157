import { useRef, useCallback, useEffect } from 'react';
import {
    IParticipantQuestionResponse,
    ScenarioSessionState,
} from 'Features/participant/workspace/scenario/types';
import {
    ParticipantSurveyFormHandles,
    ParticipantSurveyForm,
} from 'Features/participant/workspace/scenario/components/Survey/ParticipantSurveyForm';
import { ScenarioPaper } from 'Src/features/participant/workspace/scenario/components/shared/ScenarioPaper';
import { useActionButton } from 'Features/participant/workspace/scenario/hooks';
import { finishStep, reset as resetStepper, selectStepperState } from 'Features/participant/workspace/scenario/slice';
import { useDispatch, useSelector } from 'react-redux';
import { participantMutations } from 'Features/participant/workspace/scenario/queries';
import { useNavigate } from 'react-router-dom';
import { SurveyElement } from 'Shared/types';
import { isPickQuestion, SurveyQuestion } from 'Features/manager/shared/types';

interface ScreenerProps {
    title: string;
    surveyElements: SurveyElement[];
    onSubmitted?: () => void;
}

export const SurveyStepPaper = (props: ScreenerProps) => {
    const { onSubmitted, surveyElements } = props;
    const actionButton = useActionButton();
    const surveyFormRef = useRef<ParticipantSurveyFormHandles>(null);
    const state = useSelector(selectStepperState);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mutateAsync: postResponse } = participantMutations.usePostSurveyResponse();
    const { mutate: finishSession } = participantMutations.useFinishScenarioSession({
        onSuccess: (res) => {
            switch (res.actionType) {
                case 'redirectToPanel':
                    navigate(`/me/redir?url=${encodeURIComponent(res.redirectUrl)}`);
                    break;
                case 'doNothing':
                    break;
            }
            dispatch(resetStepper());
        },
    });
    const handleSubmittingChange = (isSubmitting: boolean) => {
        actionButton.setDisabled(isSubmitting);
    };

    const handleSubmit = useCallback(
        async (values: any) => {
            if (!state.ids?.stepSessionId) {
                throw new Error('Scenario session is not properly initialized');
            } else {
                console.log('values', values);
                const valuesKeys = Object.keys(values);
                const questionAnswers = valuesKeys.filter((value) => value !== 'surveyId');

                const body = {
                    sessionId: state.ids.stepSessionId,
                    questionAnswers: questionAnswers.reduce((acc, questionId) => {
                        const question = surveyElements.find((element) => element.id === questionId) as SurveyQuestion;
                        if (!question) {
                            throw new Error(`Question with id ${questionId} not found`);
                        }
                        if (isPickQuestion(question)) {
                            if (Array.isArray(values[questionId])) {
                                acc = acc.concat(
                                    values[questionId].map((optionId: string) => ({
                                        questionId,
                                        optionId: parseInt(optionId),
                                    })),
                                );

                                return acc;
                            }
                            acc.push({
                                questionId,
                                optionId: parseInt(values[questionId]),
                            });
                            return acc;
                        }

                        acc.push({
                            questionId,
                            answer: values[questionId],
                        });

                        return acc;
                    }, [] as IParticipantQuestionResponse[]),
                };
                const sessionState = await postResponse({
                    body,
                });
                dispatch(finishStep(sessionState));
                typeof onSubmitted == 'function' && onSubmitted();
                switch (sessionState) {
                    case ScenarioSessionState.started:
                    case ScenarioSessionState.finished:
                        return;
                    case ScenarioSessionState.screenOut:
                        finishSession({ urlParams: { id: state.ids.scenarioSessionId } });
                        navigate('/me/different-audience');
                        return;
                }
            }
        },
        [state.ids?.stepSessionId, navigate, postResponse, dispatch, onSubmitted],
    );

    useEffect(() => {
        actionButton.setDisabled(false);
    }, []);

    useEffect(() => {
        actionButton.setOnClick(() => {
            surveyFormRef.current?.scrollToActiveError();
        });
    }, [surveyFormRef.current]);

    return (
        <ScenarioPaper title={props.title}>
            <ParticipantSurveyForm
                ref={surveyFormRef}
                surveyElements={props.surveyElements}
                onSubmittingChange={handleSubmittingChange}
                onSubmit={handleSubmit}
            />
        </ScenarioPaper>
    );
};
