import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';
import { addError } from 'Features/participant/slice';
import { sleep } from 'Utils/promise';
import { Store } from 'redux';
import { History } from 'history';
import { history, store } from 'Src/main';

const apiClient = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    withCredentials: true,
});

export function useApiClient(authorizerFunc: () => Promise<string>) {
    const client = axios.create({
        baseURL: import.meta.env.VITE_API_BASE_URL,
        withCredentials: true,
    });
    client.interceptors.request.use(async function (config) {
        if (config.headers && !config.headers.Authorization) {
            const token = await authorizerFunc();
            config.headers.Authorization = `Bearer ${token}` || '';
        }
        return config;
    });
    client.interceptors.response.use((response) => response, getOnRejected(history, store));

    return client;
}

const httpClient = axios.create();

export function getOnRejected(_history: History, store: Store) {
    return async (error: AxiosError) => {
        const body = getFailedRequestBody(error);
        if (body) {
            Sentry.setContext('Request body', {
                body,
            });
        }

        if (error.response && error.response.status === 429 && error.config) {
            const retryAfterMs = 1000;
            retryRequest(error.config, { maxAttempts: 1, timeout: retryAfterMs });
            return;
        }

        if (error.response && error.response.status >= 400) {
            store.dispatch(addError(error.message));
            Sentry.captureException(error);
        }

        return Promise.reject(error);
    };
}

export function useRefreshToken(refreshToken?: string) {
    return apiClient.post('/api/users/refresh_token', { refreshToken: refreshToken ?? '' }, {
        triedToRefresh: true,
    } as any);
}

function isSuccess(statusCode: number) {
    return statusCode >= 200 && statusCode <= 299;
}

export { apiClient, isSuccess, httpClient };

interface retryConfig {
    maxAttempts: number;
    timeout: number;
}

export const retryRequest = async (axiosConfig: AxiosRequestConfig, { maxAttempts, timeout }: retryConfig) => {
    return new Promise(async (resolve, reject) => {
        let retries = 0;
        let isSuccess = false;
        let error;

        while (retries < maxAttempts && !isSuccess) {
            try {
                await sleep(timeout);
                const response = await axios.request(axiosConfig);
                isSuccess = true;
                resolve(response.data);
            } catch (err) {
                error = err;
            }
            retries++;
        }

        reject(error);
    });
};

export const getFailedRequestBody = (error: AxiosError) => {
    if (!error.config) {
        return;
    }
    const { data } = error.config;
    let body: Record<string, any> = {};
    if (data instanceof FormData) {
        for (const [key, value] of data.entries()) {
            body[key] = value;
        }
    } else {
        body = data;
    }
    return body;
};

export const sendSentryErrorWithBody = (error: any) => {
    const body = getFailedRequestBody(error);
    if (body) {
        Sentry.setContext('Request body', {
            body,
        });
    }

    Sentry.captureException(error);
    return error;
};

export function apiFileDownload(url: string, filename: string) {
    apiClient({
        url,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
}
