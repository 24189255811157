export function downloadFile(content: Blob | string, filename: string, contentType: string) {
    const link = document.createElement('a');
    const file = new Blob([content], { type: contentType });
    link.href = URL.createObjectURL(file);
    link.download = filename;
    link.style.display = '';
    link.click();
}

export const preloadImage = (url: string, callback: any) => {
    const img = document.createElement('img');
    img.src = url;
    img.onload = function (_e) {
        callback();
    };
};

export async function getImageDimensions(image: File) {
    return new Promise<{ height: number; width: number }>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({ height: img.height, width: img.width });
        };
        img.onerror = (err) => {
            reject(err);
        };
        img.src = URL.createObjectURL(image);
    });
}

export async function getFileFromUrl(url: string, name: string, defaultType = 'image/jpeg') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
        type: data.type || defaultType,
    });
}
