import * as React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import { useTheme, styled, SxProps, Theme } from '@mui/material/styles';
import { green, amber } from '@mui/material/colors';

type Variant = 'success' | 'warning' | 'error' | 'info';

const icons: Record<Variant, React.ElementType> = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const Span = styled('span')({
    display: 'flex',
    alignItems: 'center',
});

interface ISnackbarViewProps {
    onClose?: (event: React.SyntheticEvent<any>, reason: string) => void;
    sx?: SxProps<Theme>;
    message: string;
    content?: JSX.Element;
    variant: Variant;
}

export const Snackbar = (props: ISnackbarViewProps) => {
    const { message, variant, content, sx, ...other } = props;
    const Icon = icons[variant];
    const theme = useTheme();
    const colors = {
        success: green[600],
        error: theme.palette.error.dark,
        info: theme.palette.primary.dark,
        warning: amber[700],
    };
    return (
        <SnackbarContent
            sx={{
                width: '100%',
                background: colors[variant],
                message: { width: '100%' },
                ...sx,
            }}
            aria-describedby="client-snackbar"
            message={
                <div>
                    <Span id="client-snackbar">
                        <Icon
                            sx={{
                                fontSize: '20px',
                                opacity: 0.9,
                                marginRight: theme.spacing(1),
                            }}
                        />
                        {message}
                    </Span>
                    {content}
                </div>
            }
            {...other}
        />
    );
};

export const errorSnack = (message: string, sx?: SxProps<Theme>, content?: JSX.Element): React.ReactElement => {
    return (
        <Snackbar
            variant="error"
            sx={{
                boxSizing: 'border-box',
                ...sx,
            }}
            message={message}
            content={content}
        />
    );
};

export const successSnack = (message: string, sx?: SxProps<Theme>, content?: JSX.Element): React.ReactElement => {
    return (
        <Snackbar
            variant="success"
            sx={{
                boxSizing: 'border-box',
                ...sx,
            }}
            message={message}
            content={content}
        />
    );
};
export const infoSnack = (message: string, sx?: SxProps<Theme>, content?: JSX.Element): JSX.Element => {
    return <Snackbar variant="info" sx={{ boxSizing: 'border-box', ...sx }} message={message} content={content} />;
};

export const warningSnack = (message: string, sx?: SxProps<Theme>, content?: JSX.Element): JSX.Element => {
    return <Snackbar variant="warning" sx={{ boxSizing: 'border-box', ...sx }} message={message} content={content} />;
};
