import { useState } from 'react';
import * as React from 'react';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { Sex } from 'Shared/types';
import ExpandableCard from 'Shared/components/expandable-card';

export interface GenderQuota {
    type: 'byGender';
    quotaRules: GenderQuotaRule[];
}

export interface GenderQuotaRule {
    gender: Sex;
    percentage: number;
}

interface EditGenderQuotaProps {
    onSave: (quota: GenderQuota) => void;
    initialQuota?: GenderQuota;
}

const genderQuotaInitialValue: GenderQuotaRule = { gender: Sex.Female, percentage: 0 };
const EditGenderQuota: React.FC<EditGenderQuotaProps> = ({ onSave, initialQuota }) => {
    console.log(initialQuota);
    const [quotaRules, setQuotaRules] = useState<GenderQuotaRule[]>(initialQuota?.quotaRules || []);
    const [selectedQuota, setSelectedQuota] = useState<GenderQuotaRule>(genderQuotaInitialValue);

    const handleGenderChange = (event: SelectChangeEvent<Sex>) => {
        if (!selectedQuota) {
            return;
        }
        setSelectedQuota({ ...selectedQuota, gender: event.target.value as Sex });
    };

    const handlePercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!selectedQuota) {
            return;
        }
        setSelectedQuota({ ...selectedQuota, percentage: Number(event.target.value) });
    };

    const handleEdit = (quota: GenderQuotaRule) => {
        setSelectedQuota(quota);
    };

    const handleDelete = (quota: GenderQuotaRule) => {
        setQuotaRules(quotaRules.filter((q) => q !== quota));
    };

    const handleSubmit = () => {
        if (selectedQuota) {
            setQuotaRules([...quotaRules.filter((q) => q !== selectedQuota), selectedQuota]);
            setSelectedQuota(genderQuotaInitialValue);
        }
    };

    const handleSave = () => {
        onSave({ type: 'byGender', quotaRules });
    };

    return (
        <ExpandableCard title={<Typography variant="h6">Quota by gender</Typography>}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Gender</TableCell>
                            <TableCell>Percentage</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quotaRules.map((quota, index) => (
                            <TableRow key={index}>
                                <TableCell>{quota.gender}</TableCell>
                                <TableCell>{quota.percentage}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEdit(quota)}>Edit</Button>
                                    <Button onClick={() => handleDelete(quota)}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box p={1}>
                <FormControl fullWidth>
                    <InputLabel id="gender-select-label">Gender</InputLabel>
                    <Select
                        labelId="gender-select-label"
                        id="gender-select"
                        label="Gender"
                        value={selectedQuota?.gender || ''}
                        onChange={handleGenderChange}
                    >
                        {Object.values(Sex).map((gender) => (
                            <MenuItem key={gender} value={gender}>
                                {gender}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box p={1}>
                <TextField
                    name="percentage"
                    label="Percentage"
                    value={selectedQuota?.percentage}
                    onChange={handlePercentageChange}
                    required
                    fullWidth
                />
            </Box>
            <Box p={1}>
                <Button onClick={handleSubmit} fullWidth>
                    Submit this rule
                </Button>
            </Box>
            <Box p={1}>
                <Button variant="contained" onClick={handleSave} fullWidth>
                    Save all
                </Button>
            </Box>
        </ExpandableCard>
    );
};

export default EditGenderQuota;
