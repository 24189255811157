import { useRef, useCallback } from 'react';
import { useResizeObserver } from 'usehooks-ts';
import ReacYoutubePlayer from 'react-player/youtube';
import YouTubePlayer from 'react-player/youtube';
import { VIDEO_WIDTH_TO_HEIGHT_RATIO } from 'Features/participant/workspace/scenario/constants';
import { SHOT_INTERVAL_MS } from 'Features/participant/workspace/scenario/constants';
import { videoEventTypes, videoEvent$ } from 'Features/participant/workspace/scenario/observables/videoEvent';

interface IProgressStatus {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
}

interface IYoutubePlayerProps {
    link: string;
}

export const YoutubePlayer = (props: IYoutubePlayerProps) => {
    const playerRef = useRef<YouTubePlayer>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    // deprecated implementation
    // const [containerRef, { width, height }] = useElementSize();
    // new implementation
    const { width, height } = useResizeObserver({ ref: containerRef });

    const handleStart = useCallback(() => {
        videoEvent$.next({ status: videoEventTypes.onParticipantPlayerStart });
    }, []);
    const handlePause = useCallback(() => {
        videoEvent$.next({ status: videoEventTypes.onParticipantPlayerPause });
    }, []);

    const handleEnded = useCallback(() => {
        videoEvent$.next({ status: videoEventTypes.onParticipantPlayerEnded });
    }, []);

    const handleSeek = useCallback(() => {
        videoEvent$.next({ status: videoEventTypes.onParticipantPlayerSeek });
    }, []);

    const handlePlay = useCallback(() => {
        videoEvent$.next({ status: videoEventTypes.onParticipantPlayerPlay });
    }, []);

    const handleProgress = useCallback((status: IProgressStatus) => {
        videoEvent$.next({
            status: videoEventTypes.onParticipantPlayerVideoProgress,
            data: {
                seconds: status.playedSeconds,
                percents: status.played * 100,
            },
        });
    }, []);

    const observedWidth = width || 0;
    const observedHeight = height || 0;
    return (
        <div
            ref={containerRef}
            style={{
                width: '100%',
                height: observedWidth / VIDEO_WIDTH_TO_HEIGHT_RATIO || '100%',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: 16,
                overflow: 'hidden',
            }}
        >
            <ReacYoutubePlayer
                ref={playerRef}
                url={props.link}
                width={'100%'}
                height={observedHeight}
                controls
                onStart={handleStart}
                onProgress={handleProgress}
                onPlay={handlePlay}
                onSeek={handleSeek}
                onPause={handlePause}
                onEnded={handleEnded}
                progressInterval={SHOT_INTERVAL_MS}
            />
        </div>
    );
};
