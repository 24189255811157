import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { useCallback, useRef, useState, LegacyRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import throttle from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { Footer, useFooterButtonsSize } from '@watched-tech/wantent-ui';
import { Skeleton } from '@mui/material';
import { errorSnack, infoSnack } from 'Shared/components/snackbars/Snackbar';
import { HidingPanel } from 'Features/participant/workspace/scenario/components/shared/HidingPanel';
import { finishStep, setActionButton } from 'Features/participant/workspace/scenario/slice';
import { ILandingEvent, SessionState } from 'Features/participant/workspace/scenario/types';
import { toUTC } from 'Utils/time';
import { chunkDurationSec } from 'Features/participant/workspace/scenario/constants';
import ArrowRight  from 'Assets/svg/arrowRight.svg?react';
import { getStepperIds, getCurrentStep } from 'Features/participant/workspace/scenario/slice';
import { useRecorder } from 'Features/participant/workspace/scenario/hooks';
import { useEffectOnce } from 'usehooks-ts';
import { ScenarioPaper } from '../shared/ScenarioPaper';
import { participantMutations } from 'Features/participant/workspace/scenario/queries';
import { preloadImage } from 'Utils/io';

const ScenarioImage = styled('img')({
    width: '100%',
});

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    width: '100%',
    height: 442,

    [theme.breakpoints.up('lg')]: {
        height: 580,
    },
}));

const ImageContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: 442,
    overflow: 'hidden',
    borderRadius: 16,
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
        height: 580,
    },
}));
const PreviewImage = styled('div')({
    width: '100%',
});

interface ILandingRecorderProps {
    link: string;
}

export const LandingRecorder = (props: ILandingRecorderProps) => {
    const { t: getLabel } = useTranslation();
    const { mutateAsync: sendEvent } = participantMutations.useSendLangingSessionEvent();
    const { mutateAsync: finishSession } = participantMutations.useFinishLandingSession();
    const dispatch = useDispatch();
    const { isWebcamReady, errorMessage, recorder, finishReactionRecording } = useRecorder();
    const imageWrapperRef = useRef<HTMLDivElement>();
    const [sessionState, setSessionState] = useState<SessionState>(SessionState.preview);
    const currentStep = useSelector(getCurrentStep)?.scenarioStep;
    const stepperIds = useSelector(getStepperIds);
    const [sessionId] = useState<string | undefined>(stepperIds?.stepSessionId);
    const [isFullscreenMode, setIsFullscreenMode] = useState<boolean>(false);
    const [isControlsShown, setIsControlsShown] = useState<boolean>(false);
    const [isStarted, setIsStarted] = useState<boolean>(false);
    const [scheduledEvents, setScheduledEvents] = useState<Array<ILandingEvent>>([]);
    const [controlsDissapearTimeout, setControlsDissapearTimeout] = useState<number>();
    const [isRecordingStarted, setIsRecordingStarted] = useState<number>(); // change var name
    const [isImgLoaded, setIsImgLoaded] = useState<boolean>(false);
    const size = useFooterButtonsSize();
    useEffect(() => {
        preloadImage(props.link, () => {
            setIsImgLoaded(true);
            dispatch(
                setActionButton({
                    disabled: false,
                }),
            );
        });
    }, [props.link, dispatch]);

    useEffectOnce(() => {
        dispatch(
            setActionButton({
                disabled: true,
            }),
        );
    });

    const sendScheduledEvents = useCallback(() => {
        if (!sessionId) {
            throw new Error('Session id was undefined when trying to send events');
        }
        if (scheduledEvents.length > 0) {
            for (const scheduledEvent of scheduledEvents) {
                sendEvent({ urlParams: { id: sessionId }, body: { event: scheduledEvent } });
            }
            setScheduledEvents([]);
        }
    }, [scheduledEvents, sessionId]);

    const handleFinishClick = useCallback(() => {
        setSessionState(SessionState.finishing);
        dispatch(setActionButton({ disabled: true }));
        sendScheduledEvents();
        if (!currentStep?.id) throw new Error('Step id cannot be undefined when finishing landing');
        finishReactionRecording(currentStep?.id);
        document.fullscreenElement && document.exitFullscreen();

        finishSession({ urlParams: { id: sessionId! } }).then(() => {
            dispatch(finishStep());
        });

        dispatch(
            setActionButton({
                onClick: undefined,
                label: undefined,
            }),
        );
    }, [dispatch, finishReactionRecording, sessionId, sendScheduledEvents, currentStep?.id]);

    const handleStart = useCallback(() => {
        imageWrapperRef.current?.requestFullscreen().then(() => {
            setIsFullscreenMode(true);
            setIsStarted(true);
            setSessionState(SessionState.recording);
            if (recorder?.state !== 'recording') recorder?.start(chunkDurationSec * 1000);
            dispatch(
                setActionButton({
                    onClick: handleFinishClick,
                    label: getLabel('participant.scenarios.finish'),
                }),
            );
            imageWrapperRef.current?.addEventListener('fullscreenchange', () => {
                if (!document.fullscreenElement) {
                    clearTimeout(controlsDissapearTimeout);
                    setIsFullscreenMode(false);
                    setIsControlsShown(true);
                    recorder?.state !== 'inactive' && recorder?.stop();
                }
            });
        });
    }, [controlsDissapearTimeout, dispatch, getLabel, recorder, handleFinishClick]);

    useEffect(() => {
        if (!isStarted) {
            dispatch(
                setActionButton({
                    onClick: handleStart,
                    label: getLabel('participant.scenarios.start'),
                }),
            );
        }
    }, [dispatch, getLabel, handleStart, isStarted]);

    useEffect(() => {
        return function clear() {
            clearTimeout(controlsDissapearTimeout);
        };
    }, [controlsDissapearTimeout]);

    useEffect(() => {
        if (recorder && recorder?.state === 'inactive') {
            recorder.onstart = handleRecorderStart;
        }
    }, [recorder]);

    useEffect(() => {
        if (!isFullscreenMode && isControlsShown) {
            setIsControlsShown(false);
        }
    }, [isFullscreenMode, isControlsShown]);

    const handleMouseMove = throttle(
        () => {
            clearTimeout(controlsDissapearTimeout);
            setIsControlsShown(true);
            const timeoutId = window.setTimeout(() => {
                setIsControlsShown(false);
            }, 2000);
            setControlsDissapearTimeout(timeoutId);
        },
        200,
        { leading: true },
    );

    const sendSessionEvent = useCallback(
        (event: ILandingEvent) => {
            if (sessionId) {
                sendScheduledEvents();
                return sendEvent({ urlParams: { id: sessionId }, body: { event: event } });
            }
            setScheduledEvents([...scheduledEvents, event]);
            return Promise.resolve();
        },
        [sessionId, scheduledEvents],
    );

    const handleScroll = useCallback(
        throttle(
            (event: any) => {
                console.warn('handle scroll');
                if (event.target && isRecordingStarted) {
                    const dimensions = {
                        top: event.target.scrollTop,
                        width: event.target.getBoundingClientRect().width,
                        height: event.target.getBoundingClientRect().height,
                    };
                    const diff = new Date().getTime() - isRecordingStarted;

                    sendSessionEvent({
                        height: Math.round(dimensions.height),
                        width: Math.round(dimensions.width),
                        offsetY: Math.round(dimensions.top),
                        atTime: format(+toUTC(new Date(diff)), 'HH:mm:ss.SSS'),
                    });
                }
            },
            100,
            { trailing: true, leading: true },
        ),
        [isRecordingStarted],
    );

    const handleRecorderStart = () => {
        setIsRecordingStarted(new Date().getTime());
    };

    const controls = (
        <HidingPanel isShown={sessionState === SessionState.recording ? isControlsShown : false}>
            <>
                <Footer
                    sx={{
                        root: {
                            position: isFullscreenMode ? 'fixed' : 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                        },
                    }}
                    rightButtonsOptions={[
                        {
                            type: 'submit',
                            size,
                            variant: 'contained',
                            color: 'primary',
                            disabled: sessionState === SessionState.finishing,
                            onClick: handleFinishClick,
                            endIcon: <ArrowRight />,
                            children: getLabel('participant.scenarios.finish'),
                            dataAttrs: {
                                'data-testid': 'landing-finish-fs',
                            },
                        },
                    ]}
                />
            </>
        </HidingPanel>
    );
    return (
        <ScenarioPaper title={getLabel('participant.landing.title')}>
            {!isWebcamReady && infoSnack(getLabel('participant.watch.acceptwebcam'))}
            {errorMessage && errorSnack(errorMessage)}
            {isWebcamReady && !errorMessage && (
                <ImageContainer>
                    <PreviewImage
                        ref={imageWrapperRef as LegacyRef<HTMLDivElement>}
                        onMouseMove={isFullscreenMode ? handleMouseMove : undefined}
                        onScroll={handleScroll}
                        style={{
                            overflowY: isFullscreenMode ? 'scroll' : 'hidden',
                        }}
                    >
                        {isImgLoaded ? (
                            <ScenarioImage src={props.link} alt="Scenario landing" />
                        ) : (
                            <StyledSkeleton animation="wave" variant="rectangular" sx={{ bgcolor: 'gray.x4' }} />
                        )}
                        {controls}
                    </PreviewImage>
                </ImageContainer>
            )}
        </ScenarioPaper>
    );
};
