import { ReactNode } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { useAuthContext } from 'Shared/auth/context';
import OnlyHeaderLayout from 'Features/participant/shared/components/layouts/OnlyHeaderLayout';
import ManagerLayout from 'Pages/manager/ManagerLayout';
import { WantentProvider } from 'Shared/components';
import { LinearProgress } from '@mui/material';
import { ManagementRoles, ParticipantRoles, Roles } from 'Shared/auth/common';

interface IPrivateRouteProps {
    roles: (keyof typeof Roles)[];
    children: ReactNode;
    invalidRoleLogoutPath: string;
    Loader: React.ComponentType;
}

export const PrivateRoute = ({ roles, children, Loader, invalidRoleLogoutPath }: IPrivateRouteProps) => {
    const location = useLocation();
    const { loginWithRedirect, isLoading } = useAuth0();
    const { role, logout } = useAuthContext();

    if (isLoading) {
        return <Loader />;
    }
    const currentRole = role;
    if (!currentRole) {
        loginWithRedirect({
            appState: {
                returnTo: location.pathname,
            },
        });
        return <Loader />;
    }
    if (!roles.includes(currentRole)) {
        // if user was authenticated, but the role did not match - logout them and try to log in into home page
        logout(invalidRoleLogoutPath);
        return <Loader />;
    }
    return children as JSX.Element;
};

function ParticipantLoader() {
    return (
        <WantentProvider>
            <OnlyHeaderLayout>
                <LinearProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0, height: 2 }} />
            </OnlyHeaderLayout>
        </WantentProvider>
    );
}

function ManagerLoader() {
    return (
        <ManagerLayout>
            <LinearProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0, height: 2 }} />
        </ManagerLayout>
    );
}

export const ManagerRoute = ({ children }: Omit<IPrivateRouteProps, 'roles' | 'Loader' | 'invalidRoleLogoutPath'>) => (
    <PrivateRoute roles={ManagementRoles} Loader={ManagerLoader} invalidRoleLogoutPath={'/manager/scenarios'}>
        {children}
    </PrivateRoute>
);
export const AdminRoute = ({ children }: Omit<IPrivateRouteProps, 'roles' | 'Loader' | 'invalidRoleLogoutPath'>) => (
    <PrivateRoute roles={['Admin']} Loader={ManagerLoader} invalidRoleLogoutPath={'/manager/scenarios'}>
        {children}
    </PrivateRoute>
);
export const ParticipantRoute = ({
    children,
}: Omit<IPrivateRouteProps, 'roles' | 'Loader' | 'invalidRoleLogoutPath'>) => (
    <PrivateRoute roles={ParticipantRoles} Loader={ParticipantLoader} invalidRoleLogoutPath={'/me'}>
        {children}
    </PrivateRoute>
);
