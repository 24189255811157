import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { FormControlLabel, Link as WantentLink } from '@watched-tech/wantent-ui';
import { ParticipantFinalFormCheckbox } from 'Features/participant/shared/components/final-form';
import { Link as RouterLink } from 'react-router-dom';

export const PrivacyPolicyField: FC = () => {
    const { t: getLabel } = useTranslation();

    return (
        <Field name="ppAccepted" type="checkbox">
            {({ input, ...restFieldProps }) => (
                <FormControlLabel
                    value={input.value}
                    selected={input.value}
                    control={
                        <ParticipantFinalFormCheckbox
                            input={input}
                            {...restFieldProps}
                            checked={input.value}
                            dataAttrs={{
                                'data-testid': 'demographic-and-tos-pp-checkbox',
                            }}
                        />
                    }
                    label={
                        <>
                            {getLabel('register.form.terms')}{' '}
                            <WantentLink
                                component={RouterLink}
                                to="/privacy_policy"
                                target="_blank"
                                rel="noopener noreferrer"
                                dataAttrs={{
                                    'data-testid': 'demographic-and-tos-pp-link',
                                }}
                            >
                                {getLabel('register.form.termsLinkWords')}
                            </WantentLink>
                        </>
                    }
                />
            )}
        </Field>
    );
};
