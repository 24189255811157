import { useCallback } from 'react';
import { Button, Modal, Typography, useWantentTheme } from '@watched-tech/wantent-ui';
import { useTranslation } from 'react-i18next';
import CalibrationPng from 'Assets/images/calibration.png';
import CalibrationWebp from 'Assets/webp/instructionCalibration.webp';
import { styled } from '@mui/material/styles';

interface ICalibrationInstructionProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const Image = styled('img')(({ theme }) => ({
    borderRadius: theme.spacing(8),
}));
const Source = styled('source')(({ theme }) => ({
    borderRadius: theme.spacing(8),
}));

const CalibrationInstruction = ({ open, setOpen }: ICalibrationInstructionProps) => {
    const { t: getLabel } = useTranslation();
    const theme = useWantentTheme();
    const onClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);
    return (
        <Modal
            title={getLabel('participant.calibration.instructions')}
            open={open}
            buttons={
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                    dataAttrs={{
                        'data-testid': 'proceed-to-calibration-btn',
                    }}
                >
                    {getLabel('participant.calibration.goToSurvey')}
                </Button>
            }
            onClose={onClose}
        >
            <div>
                <Typography
                    variant="p1"
                    sx={{
                        marginBottom: theme.spacing(6),
                    }}
                >
                    {getLabel('participant.calibration.instructionDescr')}
                </Typography>
                <picture>
                    <Source type="image/webp" srcSet={CalibrationWebp} />
                    <Image src={CalibrationPng} alt="calibration animaton" />
                </picture>
            </div>
        </Modal>
    );
};

export default CalibrationInstruction;
