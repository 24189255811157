import { ReactNode, useState } from 'react';
import * as React from 'react';
import { Card, CardHeader, CardContent, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ExpandableCardProps {
    title: string | ReactNode;
    children: ReactNode;
}

const ExpandableCard: React.FC<ExpandableCardProps> = ({ title, children }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card>
            <CardHeader
                action={
                    <IconButton
                        onClick={handleExpandClick}
                        sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                }
                title={title}
            />
            <CardContent sx={{ display: expanded ? 'block' : 'none' }}>{children}</CardContent>
        </Card>
    );
};

export default ExpandableCard;
