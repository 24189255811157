import { QuestionElementType } from 'Features/manager/shared/types';
import { SurveyQuestion } from 'Features/manager/shared/types';
import { QualificationAction, ScreenerQuestion } from 'Features/manager/shared/queries';

export enum VideoType {
    youtube = 'youtube',
    videoContent = 'videoContent',
    watermarkedContent = 'watermarkedContent',
}

export interface ImageDetails {
    url: string;
    height: number;
    width: number;
    originalFileName: string;
}

export enum ElementType {
    info = 'info',
    plainTextQuestion = 'plainTextQuestion',
    pickOneQuestion = 'pickOneQuestion',
    numberQuestion = 'numberQuestion',
    pickManyQuestion = 'pickManyQuestion',
    radioPickOneQuestion = 'radioPickOneQuestion',
    ratingSliderQuestion = 'ratingSliderQuestion',
    starsQuestion = 'starsQuestion',
}
export interface SurveyElementBase {
    id: string;
    position: number;
    type: ElementType;
    text: string;
    image?: ImageDetails;
}

export type CommonQuestion = ScreenerQuestion | SurveyElement;

export interface Question extends SurveyElementBase {
    type: QuestionElementType;
    isScreening: boolean;
    imageUrl?: string;
}
export interface QuestionWithConfig<TConfig> extends Question {
    type: QuestionElementType;
    config: TConfig;
}

export interface QuestionOption {
    id: number;
    position: number;
    isAnchored: boolean;
    selectedAction?: QualificationAction;
    text: string;
}

export interface Info extends SurveyElementBase {
    type: ElementType.info;
}

export type SurveyElement = SurveyQuestion | Info;
export type OptionsQuestionType =
    | ElementType.pickOneQuestion
    | ElementType.pickManyQuestion
    | ElementType.radioPickOneQuestion;

export interface MultipartUploadPart {
    partId: string;
    partNumber: number;
}
export interface MultipartUpload {
    uploadId: string;
    filePath: string;
}

export enum Sex {
    // API-acceptable sex id -> Sex string description
    // NotKnown = "Unknown",
    Male = 'male',
    Female = 'female',
    NonBinary = 'nonBinary',
}

/**
 * Parses a string to a valid `Sex` enum value.
 * Returns `undefined` if the input is not a valid `Sex`.
 */
export function parseSex(value: string): Sex | undefined {
    // Ensure type safety by checking against the enum's values
    if (Object.values(Sex).includes(value as Sex)) {
        return value as Sex;
    }
    return undefined;
}

export function getDefaultScreeningAction(type: OptionsQuestionType) {
    switch (type) {
        case ElementType.pickOneQuestion:
        case ElementType.radioPickOneQuestion:
            return QualificationAction.qualify;
        case ElementType.pickManyQuestion:
            return QualificationAction.maySelect;
    }
}
