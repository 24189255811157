import { Container } from './styles';
import { useTranslation } from 'react-i18next';
import WantentMarkdown from 'Features/participant/shared/components/markdowns/WantentMarkdown';
import en from './en.md?raw';
import OnlyHeaderLayout from 'Features/participant/shared/components/layouts/OnlyHeaderLayout';
import { ParticipantRoles } from 'Shared/auth/common';
import { useAuthContext } from 'Shared/auth/context';

const markdowns = {
    en: en,
    uk: en,
    ru: en,
    kk: en,
    nl: en,
    pl: en,
};

export default function ContentProtection() {
    const {
        i18n: { language },
    } = useTranslation();
    const auth = useAuthContext();
    const isAuthenticated = !!auth.userId;
    return (
        <OnlyHeaderLayout isAuthenticated={isAuthenticated && auth.role && ParticipantRoles.includes(auth.role)}>
            <Container>
                <WantentMarkdown>{markdowns[language as keyof typeof markdowns]}</WantentMarkdown>
            </Container>
        </OnlyHeaderLayout>
    );
}
