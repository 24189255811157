import { createRootReducer } from './reducers';
import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { History } from 'history';

export const configureStore = (_history: History, preloadedState?: any): Store => {
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(createRootReducer(), preloadedState, composeEnhancers(applyMiddleware(thunkMiddleware)));
};
