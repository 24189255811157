import { createRef, Component } from 'react';
import { CircularProgress } from '@mui/material';
import { Player as WantentPlayer } from '@watched-tech/wantent-ui/Player';
import { IProgress } from 'Features/participant/workspace/scenario/types';
import { VIDEO_WIDTH_TO_HEIGHT_RATIO } from 'Features/participant/workspace/scenario/constants';
import { WithTranslation, withTranslation } from 'react-i18next';
import { videoEvent$, videoEventTypes } from 'Features/participant/workspace/scenario/observables/videoEvent';
import muxjs from 'mux.js';
import { VideoContainer } from './styles';

(window as any).muxjs = muxjs;

export interface ICustomPlayerState {
    videoReady: boolean;
    hasExitedFullscreen: boolean;
    hasDeniedFullscreen: boolean;
    interruptedMsg?: string;
    containerHeight?: number;
}

interface ICustomPlayerProps {
    link: string;
    interruptedMsg?: string;
    setInterruptedMsg?: (message: string) => void;
}

type CustomPlayerProps = ICustomPlayerProps & WithTranslation;

class CustomPlayerComp extends Component<CustomPlayerProps, ICustomPlayerState> {
    private containerRef = createRef<HTMLDivElement>();
    private videoRef = createRef<HTMLVideoElement>();

    constructor(props: CustomPlayerProps) {
        super(props);
        this.state = {
            videoReady: false,
            hasExitedFullscreen: false,
            hasDeniedFullscreen: false,
            containerHeight: undefined,
        };
    }

    componentDidUpdate(): void {
        const { setInterruptedMsg } = this.props;
        if (this.state.hasExitedFullscreen) {
            setInterruptedMsg instanceof Function && setInterruptedMsg(this.props.t('participant.video.interrupt'));
        }
        if (this.state.hasDeniedFullscreen) {
            setInterruptedMsg instanceof Function &&
                setInterruptedMsg(this.props.t('participant.video.fullscreenDenied'));
        }
    }

    handleFullscreenExit = () => {
        this.videoRef.current?.pause();
        this.setState({ hasExitedFullscreen: true });
    };

    handleFullscreenEnter = () => {
        if (!this.state.videoReady) {
            return;
        }
        this.videoRef.current?.play();
    };

    handlePlay = () => {
        (this.videoRef.current?.parentNode as any).requestFullscreen().catch((err: any) => {
            console.error(err);
            this.setState({ hasDeniedFullscreen: true });
        });
    };

    handlePlaying = () => {
        videoEvent$.next({ status: videoEventTypes.onParticipantPlayerPlay });
    };
    handleResize = () => {
        if (this.containerRef.current) {
            this.setState({ containerHeight: this.containerRef.current.offsetWidth / VIDEO_WIDTH_TO_HEIGHT_RATIO });
        }
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleSeek = () => {
        videoEvent$.next({ status: videoEventTypes.onParticipantPlayerSeek });
    };

    handlePause = () => {
        videoEvent$.next({ status: videoEventTypes.onParticipantPlayerPause });
    };

    handleEnded = () => {
        videoEvent$.next({ status: videoEventTypes.onParticipantPlayerEnded });
    };

    public render() {
        return (
            <VideoContainer height={this.state.containerHeight} ref={this.containerRef}>
                <WantentPlayer
                    ref={this.videoRef}
                    src={this.props.link}
                    onEnded={this.handleEnded}
                    onProgress={this.handleProgress}
                    allowCrossSiteCredentials
                    onPlay={this.handlePlay}
                    onPlaying={this.handlePlaying}
                    onPause={this.handlePause}
                    onSeek={this.handleSeek}
                    onFullscreenExit={this.handleFullscreenExit}
                    onFullscreenEnter={this.handleFullscreenEnter}
                    onLoadedData={this.handleReady}
                    sx={{
                        wrapper: { display: this.state.videoReady ? 'block' : 'none' },
                    }}
                />
                {!this.state.videoReady && <CircularProgress />}
            </VideoContainer>
        );
    }

    private handleReady = () => {
        this.setState({ videoReady: true });
    };

    private handleProgress = ({ seconds, percents }: IProgress) => {
        videoEvent$.next({
            status: videoEventTypes.onParticipantPlayerVideoProgress,
            data: {
                seconds,
                percents,
            },
        });
    };
}

export const CustomPlayer = withTranslation(undefined, { withRef: true })(CustomPlayerComp);
