import { useTranslation } from 'react-i18next';
import WrongDeviceScreenOutLayout from 'Features/participant/workspace/scenario/components/shared/layouts/WrongDeviceScreenOutLayout';
import wrongDevice from 'Assets/svg/wrongDevice.svg';

export const WrongDevice = () => {
    const { t: getLabel } = useTranslation();
    return (
        <WrongDeviceScreenOutLayout
            title={getLabel('participant.scenarios.changeDevice')}
            description={getLabel('participant.scenarios.onlyLaptops')}
            imageSrc={wrongDevice}
            dataAttrs={{
                'data-testid': 'wrong-device-paper',
            }}
        />
    );
};
