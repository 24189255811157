import { FC, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button, useWantentTheme } from '@watched-tech/wantent-ui';

import {
    ParticipantFinalFormInput,
    ParticipantFinalFormSelect,
} from 'Features/participant/shared/components/final-form';
import { composeValidators, numberRange, numberValidator, requiredValidator } from 'Utils/form';
import { StyledForm } from './DemographicsAndTOSForm.styles';
import { FormValues, IDemographicsAndTOSFormProps } from './DemographicsAndTOSForm.types';
import { useGenderOptions } from 'Features/participant/register/hooks/useGenderOptions';
import { PrivacyPolicyField } from 'Features/participant/register/components/shared/DemographicsAndTOSForm/PrivacyPolicyField';
import { ContentProtectionField } from 'Features/participant/register/components/shared/DemographicsAndTOSForm/ContentProtectionField';
import { useRoutingParams } from 'Shared/hooks';
import { parseSex } from 'Shared/types';
import { WantentFormGroup } from 'Shared/components/wantent-ui/WantentFormGroup.tsx';

/**
 * Parse the age from the preset age string
 * @param age - the age string, could be a single number or a range in the format of "18-24"
 * @returns the exact age if it's a single number, or the (lower bound of the range + 1) if it's a range, or NaN if it's not a valid age string
 */
function parsePresetAge(age: string) {
    if (!age) {
        return NaN;
    }
    const ageGroup = age.toLowerCase().split('-');
    if (ageGroup.length > 2) {
        return NaN;
    }
    if (ageGroup.length === 1) {
        return parseInt(ageGroup[0], 10);
    }

    if (ageGroup.length === 2) {
        const lowerBound = Math.min(...ageGroup.map((age) => parseInt(age, 10)));
        return lowerBound + 1;
    }

    return NaN;
}

export const DemographicsAndTOSForm: FC<IDemographicsAndTOSFormProps> = ({ onSubmit, initialValues }) => {
    const theme = useWantentTheme();
    const { pAge, pGen } = useRoutingParams<{ pAge: string; pGen: string }>();
    const { t: getLabel } = useTranslation();
    const genderOptions = useGenderOptions();

    const { initialFormValues, hasPresetAge, hasPresetGender } = useMemo(() => {
        const presetAge = parsePresetAge(pAge);
        const presetGender = pGen ? parseSex(pGen.toLowerCase()) : undefined;

        return {
            initialFormValues: {
                ...initialValues,
                age: isNaN(presetAge) ? initialValues.age : presetAge,
                sex: presetGender || initialValues.sex,
            } as FormValues,
            hasPresetAge: !isNaN(presetAge),
            hasPresetGender: !!presetGender,
        };
    }, [initialValues, pAge, pGen]);

    const required = requiredValidator(getLabel('validation.required'));
    const validateNumber = numberValidator(getLabel('validation.number'));
    const validateAge = numberRange(
        18,
        99,
        getLabel('validation.older', { years: 18 }),
        getLabel('validation.younger', { years: 99 }),
    );

    return (
        <Form initialValues={initialFormValues} onSubmit={onSubmit}>
            {({ handleSubmit, invalid, values }) => (
                <StyledForm onSubmit={handleSubmit}>
                    {hasPresetGender ? null : (
                        <Field
                            name="sex"
                            component={ParticipantFinalFormSelect}
                            label={getLabel('register.form.iDefineAs')}
                            options={genderOptions}
                            validate={required}
                            placeholder={getLabel('register.form.selectOption')}
                            dataAttrs={{
                                'data-testid': 'demographic-and-tos-select',
                            }}
                        />
                    )}
                    {hasPresetAge ? null : (
                        <Field
                            name="age"
                            component={ParticipantFinalFormInput}
                            validate={composeValidators(required, validateNumber, validateAge)}
                            placeholder={getLabel('register.form.age')}
                            label={getLabel('register.form.myAgeIs')}
                            dataAttrs={{
                                'data-testid': 'demographic-and-tos-input',
                            }}
                        />
                    )}
                    <WantentFormGroup sx={{ formWrapper: { gap: theme.spacing(6) } }}>
                        <PrivacyPolicyField />
                        <ContentProtectionField />
                    </WantentFormGroup>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={invalid || !values.ppAccepted || !values.contentProtectionAccepted}
                        sx={{ marginTop: theme.spacing(6), alignSelf: 'flex-end' }}
                        dataAttrs={{
                            'data-testid': 'registration-next-btn',
                        }}
                    >
                        {getLabel('participant.common.next')}
                    </Button>
                </StyledForm>
            )}
        </Form>
    );
};
