import catChrome from 'Assets/svg/catChrome.svg';
import WrongDeviceScreenOutLayout from 'Features/participant/workspace/scenario/components/shared/layouts/WrongDeviceScreenOutLayout';
import { useTranslation } from 'react-i18next';

export function RequireChrome() {
    const { t: getLabel } = useTranslation();
    return (
        <WrongDeviceScreenOutLayout
            title={getLabel('participant.scenarios.requireChrome.title')}
            description={getLabel('participant.scenarios.requireChrome.description')}
            imageSrc={catChrome}
            dataAttrs={{
                'data-testid': 'require-chrome-paper',
            }}
        />
    );
}
