import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { PresentationRecorderFunction } from 'Features/participant/workspace/scenario/components/reaction-recorders/PresentationRecorder';
import { LandingRecorder } from 'Features/participant/workspace/scenario/components/reaction-recorders/LandingRecorder';
import { Recorder as VideoRecorder } from 'Features/participant/workspace/scenario/components/Recorder';
import { ScenarioStep } from 'Features/participant/workspace/scenario/types';
import { WithCamConfiguration } from 'Features/participant/workspace/scenario/components/WithCamConfiguration';
import CalibrationRecorder from 'Features/participant/workspace/scenario/components/reaction-recorders/CalibrationRecorder';
import WebsiteRecorder from 'Features/participant/workspace/scenario/components/reaction-recorders/WebsiteRecorder';
import SurveyRecorder from 'Features/participant/workspace/scenario/components/reaction-recorders/SurveyRecorder';
import { StepType } from 'Features/participant/workspace/scenario/types';
import { ParticipantSurveyPaper } from 'Features/participant/workspace/scenario/components/Survey/ParticipantSurveyPaper';
import { VideoStepPlayer } from 'Features/participant/workspace/scenario/components/VideoStepPlayer';

interface IScenarioStepViewProps {
    currentStep: ScenarioStep;
}

export const ScenarioStepView: React.FC<IScenarioStepViewProps> = ({ currentStep }) => {
    const { t: getLabel } = useTranslation();
    let Component;

    switch (currentStep.stepType) {
        case StepType.presentation:
            Component = <PresentationRecorderFunction link={currentStep.presentationLink} />;
            break;
        case StepType.landing:
            Component = <LandingRecorder link={currentStep.landingLink} />;
            break;
        case StepType.survey:
        case StepType.profiling:
            if (currentStep.isRecordingRequired && !isMobile) {
                Component = (
                    <SurveyRecorder
                        surveyId={currentStep.surveyId}
                        sendText={getLabel('participant.scenarios.continue')}
                    />
                );
            } else {
                Component = <ParticipantSurveyPaper surveyId={currentStep.surveyId} />;
            }
            break;
        case StepType.video:
            if (currentStep.isRecordingRequired) {
                Component = (
                    <VideoRecorder
                        video={{
                            videoForReactionId: currentStep.videoForReactionId,
                            requiredWatchedPercentage: currentStep.requiredWatchedPercentage,
                        }}
                        type={currentStep.videoType}
                    />
                );
            } else {
                Component = (
                    <VideoStepPlayer
                        videoType={currentStep.videoType}
                        video={{
                            videoForReactionId: currentStep.videoForReactionId,
                            requiredWatchedPercentage: currentStep.requiredWatchedPercentage,
                        }}
                    />
                );
            }
            break;
        case StepType.website:
            Component = <WebsiteRecorder url={currentStep.url} />;
            break;
        case StepType.calibration:
            Component = <CalibrationRecorder />;
            break;
        default:
            throw new Error('unknown step type');
    }

    if (currentStep.isWebcamCheckRequired) {
        return <WithCamConfiguration>{Component}</WithCamConfiguration>;
    }
    return Component;
};
