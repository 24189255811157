import { Paper, useWantentTheme, Typography } from '@watched-tech/wantent-ui';
import { memo, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { getMobileView } from 'Src/features/participant/slice';
import { FormGap } from './styles';

interface IDesktopPaperProps {
    title: string | ReactNode;
    description?: string | ReactNode;
    alert?: string | ReactNode;
    children: ReactNode;
}
const paperTitleTestId = 'paper-title';
export const ScenarioPaper = memo(function ScenarioPaper({ title, description, alert, children }: IDesktopPaperProps) {
    const showMobileView = useSelector(getMobileView);
    const theme = useWantentTheme();
    return showMobileView ? (
        <FormGap data-testid="scenario-paper">
            <Typography
                dataAttrs={{ 'data-testid': paperTitleTestId }}
                sx={{ margin: `${theme.spacing(8)} 0 ${theme.spacing(6)}` }}
                color="text.disabled"
                variant="h4"
            >
                {title}
            </Typography>
            <Typography variant="p1" color="text.primary" sx={{ marginBottom: theme.spacing(12) }}>
                {description}
            </Typography>
            {children}
        </FormGap>
    ) : (
        <Paper
            title={title}
            description={description}
            alert={alert}
            dataAttrs={{ 'data-testid': 'scenario-paper' }}
            sx={{
                root: {
                    marginLeft: `${theme.sidebarWidth}px`,
                    '& > form': {
                        display: 'grid',
                        gridTemplateRows: 'auto',
                        gridGap: theme.spacing(12),
                    },
                },
            }}
        >
            {children}
        </Paper>
    );
});
