import { useCallback, useRef, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import { FormSpy } from 'react-final-form';
import { Question, setFieldTouchedMutator, Typography } from '@watched-tech/wantent-ui';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { setSurveyErrorsCount } from 'Features/participant/workspace/scenario/slice';
import { useHeaderHeight, useFooterHeight } from 'Features/participant/workspace/scenario/hooks';
import { dispatchEvent } from 'Features/participant/workspace/scenario/utils';
import { ParticipantQuestionField } from 'Features/participant/workspace/scenario/components/Survey/ParticipantQuestionField';
import { CommonQuestion } from 'Shared/types';

export const SurveyFormName = 'ScreenerParticipantForm';

function getField(
    question: CommonQuestion,
    values: Record<string, unknown>,
    errors: Record<string, unknown>,
    touched: Record<string, unknown>,
    setFieldTouched: any,
) {
    if (question.type === 'info') {
        return (
            <Typography variant="p1" component="div" sx={{ whiteSpace: 'pre-wrap', color: '#fff' }}>
                {question.text}
            </Typography>
        );
    }

    return (
        <ParticipantQuestionField
            values={values}
            question={question}
            errors={errors}
            touched={touched}
            setFieldTouched={setFieldTouched}
        />
    );
}

export interface ISurveyFormProps {
    surveyElements: CommonQuestion[];
    onSubmit?: (values: any) => void;
    onSubmittingChange?: (isSubmitting: boolean) => void;
}

const errorHeight = 20;

export interface ParticipantSurveyFormHandles {
    scrollToActiveError: () => void;
}

const ParticipantSurveyForm = forwardRef<ParticipantSurveyFormHandles, ISurveyFormProps>(
    (props: ISurveyFormProps, ref) => {
        const { surveyElements, onSubmit, onSubmittingChange } = props;
        const [errors, setErrors] = useState<Array<Array<string>>>([]);
        const dispatch = useDispatch();
        const headerHeight = useHeaderHeight();
        const footerHeight = useFooterHeight();
        const formRef = useRef<HTMLFormElement>(null);

        useImperativeHandle(
            ref,
            () => {
                return {
                    scrollToActiveError: () => {
                        const activeError = errors[0];
                        if (activeError) {
                            const currentElement = document.getElementById(activeError[0]);
                            if (currentElement) {
                                const isCurrentElementHeightIsGreaterThanViewportHeight =
                                    currentElement.offsetHeight + headerHeight + footerHeight + errorHeight >
                                    window.innerHeight;
                                currentElement?.scrollIntoView({
                                    behavior: 'smooth',
                                    block: isCurrentElementHeightIsGreaterThanViewportHeight ? 'end' : 'start',
                                });
                            }
                        }
                        formRef.current && dispatchEvent(formRef.current, 'submit');
                    },
                };
            },
            [errors, headerHeight, footerHeight],
        );
        const onFormChange = useCallback(
            (props: { errors: any; submitting: boolean }) => {
                const errorsEntries = Object.entries(props.errors);
                const errorsLength = Object.values(props.errors).length;
                if (errorsLength) {
                    setErrors(errorsEntries as Array<Array<string>>);
                }
                dispatch(setSurveyErrorsCount(errorsLength));
                props.submitting && onSubmittingChange && onSubmittingChange(props.submitting);
            },
            [dispatch],
        );

        const surveyElementsWithIdx = useMemo(() => {
            let nonInfoIdx = 0;

            return surveyElements
                .slice()
                .sort((a, b) => a.position - b.position)
                .map((surveyElement) => {
                    if (surveyElement.type !== 'info') {
                        nonInfoIdx++;
                        return {
                            ...surveyElement,
                            idx: nonInfoIdx,
                        };
                    }
                    return {
                        ...surveyElement,
                    };
                });
        }, [surveyElements]);
        return (
            <Form
                initialValues={{
                    ...surveyElements.reduce((acc: Partial<CommonQuestion>, question: CommonQuestion) => {
                        // @ts-ignore
                        acc[question.id] = '';
                        return acc;
                    }, {}),
                }}
                mutators={{ setFieldTouched: setFieldTouchedMutator }}
                onSubmit={onSubmit ? onSubmit : () => {}}
            >
                {({ handleSubmit, errors, values, touched, form }) => {
                    return (
                        <form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                            <FormSpy
                                subscription={{
                                    errors: true,
                                    submitting: true,
                                }}
                                onChange={onFormChange}
                            />
                            {surveyElementsWithIdx.map((field: CommonQuestion & { idx?: number }, idx: number) => {
                                return (
                                    <Question
                                        id={field.id}
                                        question={field.idx ? field.text : undefined}
                                        imageSrc={field.image?.url}
                                        imageWidth={field.image?.width}
                                        imageHeight={field.image?.height}
                                        idx={field.idx ? field.idx : undefined}
                                        key={idx}
                                        dataAttrs={{
                                            'data-testid': `survey-question-${field.position}`,
                                        }}
                                    >
                                        {getField(
                                            field,
                                            values as Record<string, unknown>,
                                            errors as Record<string, unknown>,
                                            touched as Record<string, unknown>,
                                            form.mutators.setFieldTouched,
                                        )}
                                    </Question>
                                );
                            })}
                        </form>
                    );
                }}
            </Form>
        );
    },
);

ParticipantSurveyForm.displayName = 'ParticipantSurveyForm';
export { ParticipantSurveyForm };
