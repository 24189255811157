import { optionQuestionTypes } from 'Shared/constants';

export * from './survey';
export * from './focusGroups';
export * from './common';

import { IQueryCallbackId } from 'Features/manager/shared/queries';
import { ElementType, Question, QuestionOption, QuestionWithConfig, SurveyElement } from 'Shared/types';
import User from 'Shared/models/User';

export interface IStepReportListParams extends IQueryCallbackId {
    stepId: string;
}
export type QuestionElementType = Exclude<ElementType, ElementType.info>;
export const isQuestionType = (elementType: ElementType): elementType is QuestionElementType => {
    return elementType !== ElementType.info;
};

type PickQuestionType = ElementType.pickManyQuestion | ElementType.pickOneQuestion | ElementType.radioPickOneQuestion;

export interface PickQuestion extends Question {
    type: PickQuestionType;
    isOptionsRandomized: boolean;
    options: QuestionOption[];
}
export function isPickQuestion(question: SurveyElement): question is PickQuestion {
    return optionQuestionTypes.includes(question.type as ElementType);
}
export interface RatingQuestion extends QuestionWithConfig<{ minValue: number; maxValue: number }> {
    type: ElementType.ratingSliderQuestion;
}

export interface StarsQuestion extends QuestionWithConfig<{ maxValue: number }> {
    type: ElementType.starsQuestion;
}

export interface NumberQuestion extends QuestionWithConfig<Record<string, never>> {
    type: ElementType.numberQuestion;
}

export interface PlainTextQuestion extends Question {
    type: ElementType.plainTextQuestion;
}

export type SurveyQuestion = PickQuestion | RatingQuestion | StarsQuestion | NumberQuestion | PlainTextQuestion;

export enum ScreenOutRule {
    oneOf = 'oneOf',
    oneOfExclusive = 'oneOf exclusive',
}

export interface CreateTenantModel {
    name: string;
    admin: {
        email: string;
        password: string;
    };
}

export interface Manager {
    email: string;
    role: string;
}

export interface ProjectManager {
    id: string;
    name: string;
    userName: string;
}

export interface FocusGroup {
    id: string;
    title: string;
    participantIds: string[];
    manager: ProjectManager;
    createdAt: string;
}

export interface FocusGroupDetails {
    id: string;
    title: string;
    focusGroupManager: ProjectManager;
    participants: User[];
}
