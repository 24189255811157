import { FC } from 'react';
import { StyledDrawer, StyledClose, DrawerContent } from './Drawer.styles';
import { IDrawerProps } from './Drawer.types';

export const Drawer: FC<IDrawerProps> = ({
    open,
    onClose,
    anchor = 'right',
    isMobile = false,
    transitionDuration = { enter: 500, exit: 500 },
    children,
    footer,
    sx,
}) => {
    return (
        <StyledDrawer
            transitionDuration={transitionDuration}
            open={open}
            anchor={anchor}
            onClose={onClose}
            sx={sx?.drawer}
        >
            <DrawerContent withFooter={!!footer} isMobile={isMobile}>
                <StyledClose onClick={onClose} sx={sx?.icon} />
                {children}
            </DrawerContent>
            {footer}
        </StyledDrawer>
    );
};
