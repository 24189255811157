import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { SxProps, Theme } from '@mui/material';
import { RadioButton } from '@watched-tech/wantent-ui';
import { DataAttrs } from '@watched-tech/wantent-ui/utils';

type RadioButtonSize = 'medium' | 'small';

interface IParticipantFinalFormRadioProps {
    disabled?: boolean;
    size?: RadioButtonSize;
    error?: string;
    sx?: SxProps<Theme>;
    dataAttrs?: DataAttrs;
}

export const ParticipantFinalFormRadio: FC<FieldRenderProps<string> & IParticipantFinalFormRadioProps> = ({
    input,
    disabled,
    error,
    sx,
    dataAttrs,
}) => {
    return (
        <RadioButton
            checked={input.checked!}
            onChange={input.onChange}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            value={input.value}
            disabled={disabled}
            size={'medium'}
            error={error}
            sx={sx}
            dataAttrs={dataAttrs}
        />
    );
};
