import { FC } from 'react';
import { Field } from 'react-final-form';
import { FormControlLabel } from '@watched-tech/wantent-ui';
import { ParticipantFinalFormCheckbox } from './ParticipantFinalFormCheckbox';
import { ParticipantFinalFormRadio } from './ParticipantFinalFormRadio';
import { WantentFormGroup } from 'Shared/components/wantent-ui/WantentFormGroup.tsx';

interface ICheckboxOption {
    name: string;
    label: string;
    value: string;
}

interface IParticipantFinalFormRadioCheckboxGroupProps {
    options?: ICheckboxOption[];
    values: Record<string, any>;
    errors: Record<string, any>;
    touched: Record<string, any>;
    showMobileView: boolean;
    groupName: string;
    componentType: 'checkbox' | 'radio';
    validate?: (value: any) => void;
}

export const ParticipantFinalFormRadioCheckboxGroup: FC<IParticipantFinalFormRadioCheckboxGroupProps> = ({
    options,
    values,
    errors,
    touched,
    groupName,
    componentType,
    validate,
    showMobileView,
    ...args
}) => {
    console.log('ParticipantFinalFormRadioCheckboxGroup');
    return (
        <WantentFormGroup error={touched[groupName] && errors[groupName] ? errors[groupName] : undefined}>
            {options &&
                options.map((option: ICheckboxOption, idx: number) => (
                    <FormControlLabel
                        key={option.value}
                        isMobile={showMobileView}
                        selected={
                            componentType === 'radio'
                                ? values[groupName] && values[groupName] === option.value
                                : values[groupName] && values[groupName].includes(option.value)
                        }
                        control={
                            <Field
                                name={groupName}
                                type={componentType}
                                component={
                                    componentType === 'checkbox'
                                        ? ParticipantFinalFormCheckbox
                                        : ParticipantFinalFormRadio
                                }
                                value={option.value}
                                validate={validate}
                                dataAttrs={{
                                    'data-testid': `${componentType === 'checkbox' ? 'checkbox' : 'radio'}-${idx}`,
                                }}
                                {...args}
                            />
                        }
                        label={option.label}
                        value={option.value}
                    />
                ))}
        </WantentFormGroup>
    );
};
