// noinspection ES6UnusedImports

import {
    useState,
    useRef,
    useContext,
    createContext,
    useEffect,
    useCallback,
} from 'react';
import { Webcam } from 'Src/features/participant/workspace/scenario/classes/Webcam';

export const WebcamContext = createContext<IWebcamContext>({ webcamRef: { current: null }, stream: null, error: '' });

interface IWebcamContext {
    webcamRef: { current: Webcam | null };
    stream: null | MediaStream;
    error: string;
    setShouldRequestWebcam?: (shouldReqWebcam: boolean) => void;
}

export const useWebcam = () => {
    const context = useContext(WebcamContext);
    useEffect(() => {
        typeof context.setShouldRequestWebcam === 'function' && context.setShouldRequestWebcam(true);
    }, [context]);

    return context;
};

export const withWebcamContext = function WithWebcamContextHOC(Component: any) {
    return function WithWebcamCtx(props: any) {
        const { stream, error } = useWebcam();
        return <Component {...props} stream={stream} webcamError={error} />;
    };
};

export const WebcamProvider = ({ children }: any) => {
    const [shouldRequestWebcam, setShouldRequestWebcam] = useState<boolean>(false);
    const [ctx, setCtx] = useState<IWebcamContext>({
        webcamRef: { current: null },
        stream: null,
        error: '',
        setShouldRequestWebcam,
    });
    const videoRef = useRef<HTMLVideoElement>(null);
    const [error] = useState<string>('');


    const onUserMediaCallback = useCallback(
        (stream: MediaStream) => {
            if (!stream) {
                /* eslint-disable-next-line no-console */
                console.error('Unexpected error recieving media stream');
                return;
            }
            if (ctx.webcamRef) {
                ctx.webcamRef.current = new Webcam(stream, videoRef.current);
            }
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
            setCtx({
                webcamRef: ctx.webcamRef,
                stream,
                error,
                setShouldRequestWebcam,
            });
        },
        [videoRef.current, ctx.webcamRef, error],
    );

    useEffect(() => {
        if (shouldRequestWebcam) {
            navigator.mediaDevices
                .getUserMedia({
                    audio: true,
                    video: {
                        width: 1280,
                        height: 720,
                        facingMode: 'user',
                    },
                })
                .then(onUserMediaCallback);
        }
    }, [onUserMediaCallback, shouldRequestWebcam]);



    return (
        <WebcamContext.Provider value={ctx}>
            {children}
            <video
                ref={videoRef}
                id="webcam-video"
                playsInline
                autoPlay
                muted
                style={{
                    width: '100%',
                    height: 0,
                    margin: '0 auto',
                    borderRadius: 16,
                    display: 'block',
                    visibility: 'hidden',
                }}
            ></video>
        </WebcamContext.Provider>
    );
};
