import { useTranslation } from 'react-i18next';
import Search  from 'Assets/svg/search.svg?react';
import { EmptyStateLayout } from 'Features/participant/workspace/dashboard/components/shared/EmptyStateLayout';

export const NoResultsFound = () => {
    const { t: getLabel } = useTranslation();
    return (
        <EmptyStateLayout
            Icon={Search}
            title={getLabel('participant.scenarios.noResults')}
            description={getLabel('participant.scenarios.changeRequest')}
        />
    );
};
