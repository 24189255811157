import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Footer, Typography, useFooterButtonsSize } from '@watched-tech/wantent-ui';
import { throttle } from 'lodash';
import { LegacyRef, Ref, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import format from 'date-fns/format';
import { errorSnack, infoSnack } from 'Features/participant/shared/components/snackbars/Snackbar';
import { HidingPanel } from 'Features/participant/workspace/scenario/components/shared/HidingPanel';
import { useDispatch, useSelector } from 'react-redux';
import {
    finishStep,
    getCurrentStep,
    getStepperIds,
    getStepperStatus,
    StepperStatus,
    setActionButton,
} from 'Features/participant/workspace/scenario/slice';
import { SessionState } from 'Features/participant/workspace/scenario/types';
import { toUTC } from 'Utils/time';
import { chunkDurationSec } from 'Features/participant/workspace/scenario/constants';
import ArrowRight  from 'Assets/svg/arrowRight.svg?react';
import Right  from 'Assets/svg/right.svg?react';
import Left  from 'Assets/svg/left.svg?react';
import { VIDEO_WIDTH_TO_HEIGHT_RATIO } from 'Features/participant/workspace/scenario/constants';
import { useEffectOnce } from 'usehooks-ts';
import { IPresentationEvent } from 'Features/participant/workspace/scenario/types';
import { useRecorder } from 'Features/participant/workspace/scenario/hooks';
import { ScenarioPaper } from 'Features/participant/workspace/scenario/components/shared/ScenarioPaper';
import { participantMutations } from 'Features/participant/workspace/scenario/queries';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const StyledPage = styled(Page)(({ height }) => ({
    width: '100%',
    height,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 16,
    overflow: 'hidden',
}));
const StyledDocument = styled(Document)({
    position: 'relative',
    width: '100%',
    height: '100%',
});

const StyledRight = styled(Right)({
    width: 24,
    height: 24,
});

const StyledLeft = styled(Left)({
    width: 24,
    height: 24,
});

interface IPresentationRecorderProps {
    link: string;
    onSessionInitialized?: () => void;
}

export const PresentationRecorderFunction = (props: IPresentationRecorderProps) => {
    const { t: getLabel } = useTranslation();
    const { link } = props;
    const dispatch = useDispatch();
    const { mutateAsync: sendEvent } = participantMutations.useSendPresentationEvent();
    const { mutateAsync: finishSession } = participantMutations.useFinishPresentationSession();
    const { isWebcamReady, errorMessage, recorder, finishReactionRecording } = useRecorder();
    const docRef = useRef<HTMLDivElement>();
    const containerRef = useRef<HTMLDivElement>();
    const stepperStatus = useSelector(getStepperStatus);
    const currentStep = useSelector(getCurrentStep)?.scenarioStep;
    const stepperIds = useSelector(getStepperIds);
    const [sessionId] = useState<string | undefined>(stepperIds?.stepSessionId);
    const [stepId] = useState<string | undefined>(currentStep?.id);
    const [isControlsShown, setIsControlsShown] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [scheduledEvents, setScheduledEvents] = useState<Array<IPresentationEvent>>([]);
    const [isFullscreenMode, setIsFullscreenMode] = useState<boolean>(false);
    const [sessionState, setSessionState] = useState<SessionState>(SessionState.preview);
    const [pdfProxy, setPdfProxy] = useState<any>();
    const [isRecordingStarted, setIsRecordingStarted] = useState<number>();
    const [controlsDissapearTimeout, setControlsDissapearTimeout] = useState<number>();
    const [height, setHeight] = useState<number>();
    const [isDocumentLoaded, setIsDocumentLoaded] = useState<boolean>(false);
    const [isStarted, setIsStarted] = useState<boolean>(false);
    const size = useFooterButtonsSize();
    const sendSessionEvent = (event: IPresentationEvent) => {
        setScheduledEvents([...scheduledEvents, event]);
        return Promise.resolve();
    };

    const handlePrev = () => {
        if (pageNumber && isRecordingStarted) {
            const diff = new Date().getTime() - isRecordingStarted;

            sendSessionEvent({
                fromSlide: pageNumber,
                toSlide: pageNumber - 1,
                atTime: format(+toUTC(new Date(diff)), 'HH:mm:ss.SSS'),
            });
            setPageNumber(pageNumber - 1);
        }
    };

    const handleNext = () => {
        if (pageNumber && isRecordingStarted) {
            const diff = new Date().getTime() - isRecordingStarted;

            sendSessionEvent({
                fromSlide: pageNumber,
                toSlide: pageNumber + 1,
                atTime: format(+toUTC(new Date(diff)), 'HH:mm:ss.SSS'),
            });
            setPageNumber(pageNumber + 1);
        }
    };

    const sendScheduledEvents = useCallback(
        async (events: IPresentationEvent[], sessionId?: string) => {
            if (events.length > 0) {
                for (const scheduledEvent of events) {
                    if (sessionId) {
                        await sendEvent({ urlParams: { id: sessionId }, body: { event: scheduledEvent } });
                    }
                }
            }
        },
        [sendEvent],
    );

    const finish = useCallback(async () => {
        if (sessionId) {
            const onFinished = () => dispatch(finishStep());
            sendScheduledEvents(scheduledEvents, sessionId);
            await finishSession({ urlParams: { id: sessionId } }).then(() => {
                onFinished && onFinished();
            });
        }
    }, [scheduledEvents, dispatch, sessionId, finishSession, sendScheduledEvents]);

    const handleFinishClick = useCallback(() => {
        setSessionState(SessionState.finishing);
        dispatch(
            setActionButton({
                disabled: true,
            }),
        );

        if (!stepId) throw new Error('Step id cannot be undefined when finishing presentation');
        finishReactionRecording(stepId);
        document.fullscreenElement && document.exitFullscreen();
        finish();
        dispatch(
            setActionButton({
                onClick: undefined,
                label: undefined,
            }),
        );
    }, [dispatch, finish, finishReactionRecording, stepId]);

    const handleMouseMove = throttle(
        () => {
            clearTimeout(controlsDissapearTimeout);
            setIsControlsShown(true);
            const timeout = window.setTimeout(() => {
                setIsControlsShown(false);
            }, 2000);
            setControlsDissapearTimeout(timeout);
        },
        200,
        { leading: true },
    );

    const onDocumentLoadSuccess = async (pdf: any) => {
        await setPdfProxy(pdf);
        setIsDocumentLoaded(true);
        if (containerRef.current) {
            setHeight(containerRef.current.offsetWidth / VIDEO_WIDTH_TO_HEIGHT_RATIO);
        }
    };

    const handleStart = useCallback(() => {
        setIsStarted(true);
        docRef.current?.requestFullscreen().then(async () => {
            if (!stepId) throw new Error('Step id cannot be undefined when starting presentation');
            if (pdfProxy) {
                setSessionState(SessionState.recording);
                setIsFullscreenMode(true);
                setHeight(containerRef.current?.offsetHeight);

                if (recorder?.state !== 'recording') recorder?.start(chunkDurationSec * 1000);
                docRef.current?.addEventListener('fullscreenchange', () => {
                    if (!document.fullscreenElement) {
                        clearTimeout(controlsDissapearTimeout);
                        setIsControlsShown(true);
                        setIsFullscreenMode(false);
                        setHeight(containerRef.current!.offsetWidth / VIDEO_WIDTH_TO_HEIGHT_RATIO);
                        finishReactionRecording(stepId);
                    }
                });
            }
        });
        dispatch(
            setActionButton({
                onClick: handleFinishClick,
                label: getLabel('participant.scenarios.finish'),
            }),
        );
    }, [
        controlsDissapearTimeout,
        dispatch,
        finishReactionRecording,
        stepId,
        getLabel,
        handleFinishClick,
        pdfProxy,
        recorder,
    ]);

    const handleRecorderStart: EventListener = () => {
        setIsRecordingStarted(new Date().getTime());
    };

    useEffectOnce(() => {
        dispatch(
            setActionButton({
                disabled: true,
                label: getLabel('participant.scenarios.start'),
            }),
        );
    });

    useEffect(() => {
        if (recorder && recorder.state === 'inactive') {
            recorder.onstart = handleRecorderStart;
        }
    }, [recorder]);

    useEffect(() => {
        if (isDocumentLoaded && !isStarted) {
            dispatch(setActionButton({ disabled: false, onClick: handleStart }));
        }
    }, [isDocumentLoaded, dispatch, isStarted, handleStart]);

    useEffect(() => {
        if (!isFullscreenMode && isControlsShown) {
            setIsControlsShown(false);
        }
    }, [isFullscreenMode, isControlsShown]);

    useEffect(() => {
        return () => {
            clearTimeout(controlsDissapearTimeout);
        };
    }, [controlsDissapearTimeout]);

    if (stepperStatus !== StepperStatus.ready) {
        return <LinearProgress />;
    }
    const controlsGrid = (
        <HidingPanel
            isShown={sessionState === SessionState.recording ? isControlsShown : false}
            sx={{
                background: 'white',
                padding: '16px',
            }}
        >
            <>
                <Footer
                    sx={{
                        root: {
                            position: 'absolute',
                            bottom: 0,
                        },
                    }}
                    leftButtonsOptions={[
                        {
                            type: 'button',
                            variant: 'outlined',
                            color: 'primary',
                            onClick: handlePrev,
                            disabled: pageNumber === 1,
                            size,
                            children: <StyledLeft />,
                            dataAttrs: {
                                'data-testid': `presentation-prev-page-btn-${pageNumber}`,
                            },
                        },
                        {
                            variant: 'outlined',
                            color: 'secondary',
                            type: 'button',
                            size,
                            sx: {
                                border: 'none !important',
                                '&:hover': {
                                    background: 'transparent',
                                    cursor: 'auto',
                                },
                            },
                            children: (
                                <Typography
                                    variant="b2"
                                    color="text.primary"
                                >{`${pageNumber}/${pdfProxy?.numPages}`}</Typography>
                            ),
                            dataAttrs: {
                                'data-testid': 'presentation-page-number',
                            },
                        },
                        {
                            type: 'button',
                            variant: 'outlined',
                            color: 'primary',
                            onClick: handleNext,
                            disabled: pageNumber === pdfProxy?.numPages,
                            size,
                            children: <StyledRight />,
                            dataAttrs: {
                                'data-testid': `presentation-next-page-btn-${pageNumber}`,
                            },
                        },
                    ]}
                    rightButtonsOptions={[
                        {
                            type: 'submit',
                            size,
                            variant: 'contained',
                            color: 'primary',
                            disabled: sessionState === SessionState.finishing,
                            onClick: handleFinishClick,
                            endIcon: <ArrowRight />,
                            children: getLabel('participant.scenarios.finish'),
                            dataAttrs: {
                                'data-testid': 'presentation-finish-fs',
                            },
                        },
                    ]}
                />
            </>
        </HidingPanel>
    );

    return (
        <div onMouseMove={document.fullscreenElement ? handleMouseMove : undefined}>
            <ScenarioPaper title={getLabel('participant.presentation.title')}>
                {!isWebcamReady && infoSnack(getLabel('participant.watch.acceptwebcam'))}
                {errorMessage && errorSnack(errorMessage)}
                {isWebcamReady && !errorMessage && (
                    <StyledDocument
                        inputRef={docRef as Ref<HTMLDivElement>}
                        file={link}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <div
                            style={{
                                height: '100%',
                            }}
                            ref={containerRef as LegacyRef<HTMLDivElement>}
                        >
                            <StyledPage height={height} pageNumber={pageNumber} />
                            {controlsGrid}
                        </div>
                    </StyledDocument>
                )}
            </ScenarioPaper>
        </div>
    );
};
