import { memo, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useVideoRecorder, useCheckUserPresence } from 'Features/participant/workspace/scenario/hooks';
import { CustomPlayer } from 'Features/participant/workspace/scenario/components/WantentShakaPlayer';
import { VideoType } from 'Shared/types';
import { participantQueries } from 'Features/participant/workspace/scenario/queries';
import { infoSnack, errorSnack } from 'Shared/components/snackbars/Snackbar';
import 'shaka-player/dist/controls.css';
import { ScenarioPaper } from '../shared/ScenarioPaper';
import { Alert } from '@watched-tech/wantent-ui';
import { useSelector } from 'react-redux';
import { selectStepperState } from 'Features/participant/workspace/scenario/slice';

interface ICustomVideoRecorderProps {
    video: {
        videoForReactionId: string;
        requiredWatchedPercentage: number;
    };
    videoType: VideoType;
}

export const CustomVideoRecorder: React.FC<ICustomVideoRecorderProps> = memo(
    ({ video: { videoForReactionId, requiredWatchedPercentage }, videoType }) => {
        const { t: getLabel } = useTranslation();
        const [interruptedMsg, setInterruptedMsg] = useState<string>();
        const uri = videoType === VideoType.videoContent ? '/api/me/videos/custom' : '/api/me/videos/watermarked';
        const { ids } = useSelector(selectStepperState);
        const { data: customVideoObj, error: getCustomVideoObjError } = participantQueries.useCustomVideoLinkObj(
            { uri, videoForReactionId, stepSessionId: ids?.stepSessionId },
            {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
            },
        );
        const { isWebcamReady, errorMessage, webcamRef, recorder } = useVideoRecorder(requiredWatchedPercentage);
        const userPresenceMsg = useCheckUserPresence(webcamRef, recorder);

        return customVideoObj?.link ? (
            <ScenarioPaper
                title={getLabel('participant.video.title')}
                alert={
                    interruptedMsg || userPresenceMsg ? (
                        <Alert
                            type="warning"
                            text={interruptedMsg ? interruptedMsg : userPresenceMsg ? userPresenceMsg : ''}
                        />
                    ) : undefined
                }
            >
                {!isWebcamReady && infoSnack(getLabel('participant.watch.acceptwebcam'))}
                {errorMessage && errorSnack(errorMessage)}
                {getCustomVideoObjError instanceof Error && errorSnack(getCustomVideoObjError.message)}
                {isWebcamReady && !errorMessage && (
                    <CustomPlayer
                        link={customVideoObj?.link}
                        interruptedMsg={interruptedMsg}
                        setInterruptedMsg={setInterruptedMsg}
                    />
                )}
            </ScenarioPaper>
        ) : null;
    },
);
