import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IPasswordCredentials {
    email: string;
    password: string;
}
export type UserCredentials = IPasswordCredentials ;

export type LoginMode = 'password';

interface ILoginReducerState {
    isFetching: boolean;
    isAuthenticated: boolean;
    errorMessage?: string;
    creds?: UserCredentials;
}

export const initialState: ILoginReducerState = {
    isFetching: false,
    isAuthenticated: false,
    errorMessage: undefined,
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        requestLogin(state) {
            state.isFetching = true;
            state.isAuthenticated = false;
        },
        loginSuccess(state) {
            state.isFetching = false;
            state.isAuthenticated = true;
        },
        loginFailure(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.isAuthenticated = false;
            state.errorMessage = action.payload;
        },
    },
});

export const { actions, reducer } = loginSlice;
export const { requestLogin, loginSuccess, loginFailure } = actions;
