import { ReactNode } from 'react';
import ParticipantHeader from 'Features/participant/shared/components/Header';
import { Background } from './styles';
import { useSelector } from 'react-redux';
import { getMobileView } from 'Features/participant/slice';

// no header & no footer on mobile devices
// has header on desktop
interface IRegisterLayoutProps {
    children: ReactNode;
}

const RegisterLayout = ({ children }: IRegisterLayoutProps) => {
    const showMobileView = useSelector(getMobileView);
    return (
        <Background>
            {!showMobileView && <ParticipantHeader isAuthenticated={false} />}
            {children}
        </Background>
    );
};

export default RegisterLayout;
