// create state with settings, filters, and useGlobal... flags from index.ts
import { IFilters, ISettings, IStatsContainer, ITileJson, ITileStats, SectionName } from 'Pages/manager/tile/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hasScene } from 'Pages/manager/tile/utils';
import { Metric } from './constants';

const initialState: TileState = {
    tile: undefined,
    settings: undefined,
    filters: undefined,
    useGlobalInsideSection: false,
    useGlobalInsideSelectedRegion: false,
};

interface TileState {
    tile: ITileJson | undefined;
    settings: ISettings | undefined;
    filters: IFilters | undefined;
    useGlobalInsideSection: boolean;
    useGlobalInsideSelectedRegion: boolean;
}

function getInitialSettings(tileJson: ITileJson) {
    const globalMaxValues = applyAggregator(tileJson.all.stats, tileJson.outputs, Math.max);
    const globalMinValues = applyAggregator(tileJson.all.stats, tileJson.outputs, Math.min);
    return {
        ageSection: { maxValues: globalMaxValues, minValues: globalMinValues },
        genderSection: { maxValues: globalMaxValues, minValues: globalMinValues },
        allSection: { maxValues: globalMaxValues, minValues: globalMinValues },
        questionSections:
            tileJson.questions?.map(() => ({ maxValues: globalMaxValues, minValues: globalMinValues })) || [],
    };
}

function applyAggregator(stats: ITileStats[], outputs: string[], aggregateFunc: (...param: number[]) => number) {
    return outputs.reduce((maxValues, name) => {
        maxValues[name] = aggregateFunc(...stats.map((s: ITileStats) => s[name as keyof ITileStats]));
        return maxValues;
    }, {} as Record<string, number>);
}

function getSectionSettings(
    tileJson: ITileJson,
    useGlobalInsideSection = false,
    useGlobalInsideSelectedRegion = false,
    filters?: IFilters,
): ISettings {
    const selectedOutputs = filters?.outputs || tileJson.outputs;

    function getSectionValues(section: { stats: ITileStats[] }[]) {
        if (useGlobalInsideSelectedRegion && filters) {
            const { fromIdx, toIdx } = filters;
            return section.map((s) => s.stats.slice(fromIdx, toIdx + 1)).flatMap((s) => s);
        }
        return section.flatMap((s) => s.stats);
    }

    function getSectionSettings<TSection extends SectionName>(
        tileJson: Record<TSection, IStatsContainer[]>,
        section: TSection,
    ) {
        if (!useGlobalInsideSection) {
            return { maxValues: undefined, minValues: undefined };
        }

        const sectionMaxValues = applyAggregator(getSectionValues(tileJson[section]), selectedOutputs, Math.max);
        const sectionMinValues = applyAggregator(getSectionValues(tileJson[section]), selectedOutputs, Math.min);
        return { maxValues: sectionMaxValues, minValues: sectionMinValues };
    }

    const ageSection = getSectionSettings(tileJson, 'ageRanges');
    const genderSection = getSectionSettings(tileJson, 'genders');
    const questionSections =
        tileJson['questions']?.map((q) => {
            return getSectionSettings(q, 'options');
        }) || [];
    const allSection = {
        maxValues: applyAggregator(getSectionValues([{ stats: tileJson.all.stats }]), selectedOutputs, Math.max),
        minValues: applyAggregator(getSectionValues([{ stats: tileJson.all.stats }]), selectedOutputs, Math.min),
    };

    return { ageSection, genderSection, questionSections, allSection };
}

// use redux-toolkit to create a slice of state
export const tileSlice = createSlice({
    name: 'managerTileRenderer',
    initialState,
    reducers: {
        setTile: (state, action: PayloadAction<ITileJson>) => {
            state.tile = action.payload;

            state.filters = {
                outputs: action.payload.outputs,
                timestamps: action.payload.timestamps,
                fromIdx: 0,
                toIdx: action.payload.timestamps.length - 1,
            };

            state.settings = getInitialSettings(action.payload);
        },
        resetFilters: (state) => {
            if (!state.tile) {
                state.filters = undefined;
                return;
            }
            state.filters = {
                outputs: state.tile.outputs,
                timestamps: state.tile.timestamps,
                fromIdx: 0,
                toIdx: state.tile.timestamps.length - 1,
            };
        },
        applyScenesFilter: (
            state,
            action: PayloadAction<{ startScene: string; endScene: string; outputs: Metric[] }>,
        ) => {
            if (!state.tile) return;
            const fromIdx = state.tile.timestamps.findIndex(hasScene(action.payload.startScene));
            const toIdx = state.tile.timestamps.findIndex(hasScene(action.payload.endScene));

            if (fromIdx == undefined || toIdx == undefined) return;

            state.filters = {
                outputs: action.payload.outputs || state.filters?.outputs || state.tile.outputs,
                fromIdx,
                toIdx,
                timestamps: state.tile.timestamps.slice(fromIdx, toIdx + 1),
            };
        },
        updateSettings: (
            state,
            action: PayloadAction<{ useGlobalInsideSection: boolean; useGlobalInsideSelectedRegion: boolean }>,
        ) => {
            const { useGlobalInsideSection, useGlobalInsideSelectedRegion } = action.payload;
            if (!state.tile) return;
            state.settings = getSectionSettings(
                state.tile,
                useGlobalInsideSection,
                useGlobalInsideSelectedRegion,
                state.filters,
            );
        },
    },
});

// export the actions and reducer
export const { setTile, resetFilters, applyScenesFilter, updateSettings } = tileSlice.actions;
export const reducer = tileSlice.reducer;
