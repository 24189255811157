import { FormGroup, IFormGroupProps, useWantentTheme } from '@watched-tech/wantent-ui';
import React from 'react';

export const WantentFormGroup: React.FC<IFormGroupProps> = ({ children, ...props }) => {
    const theme = useWantentTheme();
    return (
        <FormGroup
            sx={{
                formWrapper: {
                    [theme.breakpoints.up('md')]: {
                        gap: theme.spacing(6),
                    },
                },
            }}
            {...props}
        >
            {children}
        </FormGroup>
    );
};
