import { useCallback, useEffect, useMemo, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Sidebar, useWantentTheme } from '@watched-tech/wantent-ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    ParticipantFinalFormRating,
    ParticipantFinalFormTextarea,
} from 'Features/participant/shared/components/final-form';
import {
    getSidebarSteps,
    reset as resetStepper,
    getScenarioSessionId,
} from 'Features/participant/workspace/scenario/slice';
import { FlexWrapper } from 'Features/participant/workspace/scenario/styles';
import { SidebarWrapper } from 'Features/participant/workspace/scenario/styles';
import { isGuid } from 'Utils/isGuid';
import { dispatchEvent } from 'Features/participant/workspace/scenario/utils';
import { useActionButton } from 'Features/participant/workspace/scenario/hooks/useActionButton';
import ScenarioLayout from 'Features/participant/workspace/scenario/components/shared/layouts/ScenarioLayout';
// import { FormGap } from 'Features/participant/workspace/scenario/components/ScenarioStepView/styles';
import {
    VirtualKeyboardContext,
    withVirtualKeyboardCtx,
} from 'Features/participant/workspace/scenario/context/virtualKeyboardContext/VirtualKeyboardProvider';
import { getMobileView } from 'Features/participant/slice';
import { useRoutingParams } from 'Src/shared/hooks';
import { ScenarioPaper } from 'Features/participant/workspace/scenario/components/shared/ScenarioPaper';
import { participantMutations } from 'Features/participant/workspace/scenario/queries';

const Feedback = () => {
    const { scenarioId } = useRoutingParams<{ scenarioId: string }>();
    const { t: getLabel } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const feedbackFormRef = useRef<HTMLFormElement>(null);
    const sidebarSteps = useSelector(getSidebarSteps);
    const [activeId, setActiveId] = useState(1);
    const [isActionBtnReady, setIsActionBtnReady] = useState<boolean>(false);
    const { mutateAsync: postFeedback } = participantMutations.usePostParticipantFeedback();
    const actionButton = useActionButton();
    const sessionId = useSelector(getScenarioSessionId);
    const { breakpoints } = useWantentTheme();
    const matchesMd = useMediaQuery(breakpoints.down('md'));
    const context = useContext(VirtualKeyboardContext);
    const showMobileView = useSelector(getMobileView);

    const onFeedbackSubmit = useCallback(() => {
        feedbackFormRef.current && dispatchEvent(feedbackFormRef.current, 'submit');
    }, [sessionId]);

    useEffect(() => {
        if (!isActionBtnReady) {
            actionButton.setDisabled(false);
            actionButton.setOnClick(onFeedbackSubmit);
            setIsActionBtnReady(true);
        }
    }, [actionButton, onFeedbackSubmit, isActionBtnReady]);
    useEffect(() => {
        setActiveId(2);
    }, []);

    const onFocus = () => {
        context?.setIsKeyboardShown(true);
    };
    const onBlur = () => {
        context?.setIsKeyboardShown(false);
    };

    const handleHomeClick = useCallback(
        (values: any) => {
            if (isGuid(scenarioId)) {
                postFeedback({
                    body: {
                        scenarioId: scenarioId,
                        comment: values.comment,
                        rating: parseInt(values.rating) || undefined,
                    },
                });
            }
            navigate('/me');
            dispatch(resetStepper());
        },
        [navigate, postFeedback, dispatch, scenarioId],
    );
    const thankyouSteps = useMemo(
        () => sidebarSteps.slice(-2).map((step, idx) => ({ ...step, id: idx + 1 })),
        [sidebarSteps],
    );

    const form = (
        <Form onSubmit={handleHomeClick}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} ref={feedbackFormRef}>
                    <Typography variant={matchesMd ? 'h5' : 'h4'} color="text.primary">
                        {getLabel(`participant.thankyou.${showMobileView ? 'rateMobile' : 'experienceRate'}`)}
                    </Typography>
                    <Field
                        name={'rating'}
                        component={ParticipantFinalFormRating}
                        showMobileView={showMobileView}
                        max={10}
                        sx={{ wrapper: { marginBottom: theme.spacing(12) } }}
                    />
                    <Typography sx={{ marginBottom: theme.spacing(6) }} variant={'h4'} color="text.primary">
                        {getLabel('participant.thankyou.suggestions')}
                    </Typography>
                    <Field
                        name={'comment'}
                        component={ParticipantFinalFormTextarea}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </form>
            )}
        </Form>
    );

    return (
        <ScenarioLayout>
            {!showMobileView && (
                <SidebarWrapper>
                    <Sidebar steps={thankyouSteps} activeId={activeId} />
                </SidebarWrapper>
            )}
            <FlexWrapper>
                <ScenarioPaper
                    title={getLabel('participant.thankyou.title')}
                    description={getLabel('participant.thankyou.description')}
                >
                    {form}
                </ScenarioPaper>
            </FlexWrapper>
        </ScenarioLayout>
    );
};

export default withVirtualKeyboardCtx(Feedback);
