import { useState, useCallback, useEffect } from 'react';
import * as React from 'react';
import { CssBaseline, Typography, Grid, Button, useMediaQuery } from '@mui/material';
import { InjectedFormProps, Field, reduxForm, SubmissionError } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { loginSuccess } from 'Pages/login/LoginPage/components/slice';
import { FinalFormInput } from 'Features/manager/shared/components/form-inputs';
import { SexSelect } from 'Features/manager/shared/components/form-inputs/SexSelect';
import { InfoDialog } from 'Features/participant/register/components/InfoDialog';
import { Sex } from 'Shared/types';
import { TermsAcceptedCheckbox } from 'Features/participant/register/components/TermsAcceptedCheckbox';

import { mapErrorsToFieldErrorMessages, numberValidator, requiredValidator } from 'Utils/form';
import { apiClient } from 'Utils/axios';
import { isMobile } from 'react-device-detect';
import { useWantentTheme } from '@watched-tech/wantent-ui';
import { removeAuthToken, signInWntUserWithTokens } from 'Shared/auth/schemes/wnt';
import { Roles } from 'Shared/auth/common';
import { useAuthContext } from 'Shared/auth/context';
import { useNavigate } from 'react-router-dom';

async function registerWithToken(formValues: any) {
    const { password, sex, age, id } = formValues;
    const data = new FormData();
    data.append('password', password);
    data.append('sex', sex);
    data.append('age', age);
    data.append('id', id);
    const registerResponse = await apiClient.post('/api/users/register_token', data);
    const responseJson = registerResponse.data.data;
    if (registerResponse.status === 400) {
        if (responseJson.message) {
            throw new SubmissionError(responseJson.message);
        }
        if (responseJson.errors) {
            const fieldErrorMessages = mapErrorsToFieldErrorMessages(responseJson.errors);
            throw new SubmissionError(fieldErrorMessages);
        }
    }

    return responseJson;
}

interface IFormProps {
    id: string;
    hasPresetPassword: boolean;
}

function RegisterWithTokenForm(props: InjectedFormProps<any, IFormProps> & IFormProps) {
    const { handleSubmit, invalid, id, hasPresetPassword, initialize, touch } = props;
    const theme = useWantentTheme();
    const { t: getLabel } = useTranslation();
    const dispatch = useDispatch();
    const auth = useAuthContext();
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const [areTermsAccepted, setAreTermsAccepted] = useState(false);
    const [isRemeberDialogOpen, setRememberModalOpen] = useState(false);

    const handleTermsAcceptedChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAreTermsAccepted(event.target.checked);
    }, []);
    useEffect(() => {
        initialize({
            id: id,
            sex: Sex.Male,
        });
        touch('email', 'id');
    }, [id, touch, initialize]);
    const handleRegisterFormSubmit = useCallback(
        (values: any) => {
            removeAuthToken();
            return registerWithToken(values).then(({ accessToken, refreshToken }) => {
                signInWntUserWithTokens(accessToken, refreshToken, auth);
                setRememberModalOpen(true);
            });
        },
        [setRememberModalOpen],
    );

    const handleRemeberDialogClose = useCallback(() => {
        dispatch(loginSuccess());
        if (auth.role === Roles.FocusGroupManager || auth.role === Roles.Admin) {
            navigate('/manager');
        }
        if (auth.role === Roles.FocusGroupParticipant) {
            if (!(isMobile || matchesMd)) {
                navigate('/survey-disclaimer');
            } else {
                navigate('/me');
            }
        }
    }, [dispatch, matchesMd, auth.role]);

    const required = useCallback(requiredValidator(getLabel('validation.required')), [getLabel('validation.required')]);
    const validateNumber = useCallback(numberValidator(getLabel('validation.number')), [getLabel('validation.number')]);
    const passwordField = hasPresetPassword ? undefined : (
        <Grid item xs={12}>
            <Field
                name="password"
                component={FinalFormInput}
                validate={required}
                label={getLabel('register.form.password')}
                id="password"
                type="password"
                autoComplete="password"
                aria-describedby="password-error-text"
            />
        </Grid>
    );

    return (
        <div
            style={{
                margin: theme.spacing(8, 4),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <CssBaseline />
            <Typography component="h1" variant="h5">
                {getLabel('register.heading')}
            </Typography>
            <form
                style={{
                    width: '100%', // Fix IE 11 issue.
                    marginTop: theme.spacing(6),
                    marginBottom: '0px',
                }}
                onSubmit={handleSubmit(handleRegisterFormSubmit)}
            >
                <Grid container spacing={3}>
                    <Field
                        name="id"
                        component={FinalFormInput}
                        validate={required}
                        id="id"
                        hidden={true}
                        style={{ display: 'none' }}
                    />
                    {passwordField}
                    <Grid item xs={12}>
                        <Field
                            name="age"
                            component={FinalFormInput}
                            validate={[required, validateNumber]}
                            label={getLabel('register.form.age')}
                            id="age"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Field name="sex" component={SexSelect} label={getLabel('register.form.sex')} id="sex" />
                    </Grid>
                    <Grid item xs={12}>
                        <TermsAcceptedCheckbox accepted={areTermsAccepted} onChange={handleTermsAcceptedChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            id="register-button"
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ marginTop: theme.spacing(3) }}
                            disabled={invalid || !areTermsAccepted}
                        >
                            {getLabel('register.form.registerButton')}
                        </Button>
                    </Grid>
                    {
                        <InfoDialog
                            isOpen={isRemeberDialogOpen}
                            onClose={handleRemeberDialogClose}
                            buttonLabel={getLabel('register.form.gotit')}
                            buttonProps={{ id: 'gotit-button' }}
                            title={getLabel('register.form.nextLoginTitle')}
                            content={
                                <div>
                                    <Typography>
                                        {getLabel(
                                            hasPresetPassword
                                                ? 'register.form.nextLoginPresetPasswordMessage'
                                                : 'register.form.nextLoginCustomPasswordMessage',
                                        )}
                                    </Typography>
                                </div>
                            }
                        />
                    }
                </Grid>
            </form>
        </div>
    );
}

export default reduxForm<any, IFormProps>({
    form: 'RegisterWithTokenForm',
})(RegisterWithTokenForm);
