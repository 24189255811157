import { useCallback, useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useWantentTheme, Header, Button } from '@watched-tech/wantent-ui';
import { useMatch, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { setSurveyDisclaimerAccepted } from 'Features/participant/register/utils';
import Leave  from 'Assets/svg/leave.svg?react';
import avatarPath from 'Assets/svg/userProfile.svg';
import Logout  from 'Assets/svg/logout.svg?react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'Shared/auth/context';

interface IParticipantHeaderProps {
    isAuthenticated: boolean;
}

const ParticipantHeader = ({ isAuthenticated }: IParticipantHeaderProps) => {
    const theme = useWantentTheme();
    const auth = useAuthContext();
    const { t: getLabel } = useTranslation();
    const navigate = useNavigate();
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const scenarioRouteMatch = useMatch({
        path: '/me/scenario/:scenarioId',
    });
    const thankRouteMatch = useMatch({
        path: '/me/feedback',
    });
    const screenOutRouteMatch = useMatch({
        path: '/me/different-audience',
    });

    const handleLogoutClick = useCallback(() => {
        auth.logout('/');
        setSurveyDisclaimerAccepted('false');
        Sentry.setUser(null);
    }, [navigate]);
    const profileOptions = useMemo(
        () => [
            {
                label: getLabel('participant.layout.logout'),
                endIcon: <Logout color={theme.palette.secondary.main} />,
                onOptionClick: handleLogoutClick,
                sx: {
                    typography: {
                        color: theme.palette.secondary.main,
                    },
                    icon: {
                        color: theme.palette.secondary.main,
                    },
                    root: {
                        zIndex: 999,
                    },
                },
            },
        ],
        [handleLogoutClick, theme.palette.secondary.main],
    );
    const leaveToMain = useCallback(() => {
        navigate('/me');
    }, [navigate]);
    return (matchesMd || isMobile) && (scenarioRouteMatch || thankRouteMatch || screenOutRouteMatch) ? (
        <Header
            isTransparent
            LeftSideComponent={() => (
                <Button
                    onClick={leaveToMain}
                    variant="text"
                    color="primary"
                    startIcon={<Leave />}
                    size="small"
                    dataAttrs={{
                        'data-testid': 'leave-survey-btn',
                    }}
                >
                    {getLabel('participant.layout.leave')}
                </Button>
            )}
            avatarImage={avatarPath}
            profileOptions={profileOptions}
            isAuthenticated={isAuthenticated}
        />
    ) : (
        <Header avatarImage={avatarPath} profileOptions={profileOptions} isAuthenticated={isAuthenticated} />
    );
};

export default ParticipantHeader;
