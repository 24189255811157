import { useState, useCallback, FC, SyntheticEvent } from 'react';
import { Typography, Button } from '@watched-tech/wantent-ui';
import { Select } from '@watched-tech/wantent-ui';
import { ChipContainer } from './styles';
import { IMobileFiltersProps } from './types';
import { DASHBOARD_SORT_OPTIONS, Chips, SortKind } from 'Features/participant/workspace/dashboard/constants';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { getMobileView } from 'Features/participant/slice';
import { Drawer } from '../Drawer';
import { MobileFooter } from 'Features/participant/shared/components/MobileFooter';

export const MobileFilters: FC<IMobileFiltersProps> = ({
    sortValue,
    onSortChange,
    statusFilters,
    setStatusFilters,
}) => {
    const { t: getLabel } = useTranslation();
    const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
    const [sort, setSort] = useState<SortKind>(sortValue);
    const [tagFilters, setTagFilters] = useState(statusFilters);
    const hasStatusFiltersChanged = !isEqual(statusFilters, tagFilters);
    const showMobileView = useSelector(getMobileView);
    const onOpen = useCallback(() => {
        setIsFiltersOpen(true);
    }, [setIsFiltersOpen]);
    const onClose = useCallback(() => {
        setIsFiltersOpen(false);
    }, [setIsFiltersOpen]);

    const onSelectChange = useCallback((_e: SyntheticEvent, value: string | number) => {
        setSort(value as SortKind);
    }, []);

    const onTagSelect = (name: string, enabled: boolean) => {
        setTagFilters({
            ...tagFilters,
            [name]: !enabled,
        });
    };

    const onCancel = useCallback(() => {
        setSort(sortValue);
        setTagFilters(statusFilters);
        onClose();
    }, [onClose, sortValue, statusFilters]);

    const applyFilters = useCallback(() => {
        onSortChange(sort);
        setStatusFilters(tagFilters);
        onClose();
    }, [onClose, sort, tagFilters, setStatusFilters, onSortChange]);
    const footer = (
        <MobileFooter>
            <Button
                disabled={!hasStatusFiltersChanged && sortValue === sort}
                fullWidth
                variant="contained"
                color="primary"
                onClick={applyFilters}
            >
                {getLabel('participant.scenarios.applyFilters')}
            </Button>
        </MobileFooter>
    );
    return (
        <>
            <Typography
                onClick={onOpen}
                variant="b2"
                color="text.secondary"
                sx={{
                    cursor: 'pointer',
                }}
                dataAttrs={{ 'data-testid': 'dashboard-mobile-filters-and-sort' }}
            >
                {getLabel('participant.scenarios.filtersAndSort')}
            </Typography>
            <Drawer open={isFiltersOpen} onClose={onCancel} footer={footer} isMobile={showMobileView}>
                <Typography variant="h6" color="text.disabled" component="div" sx={{ marginBottom: 8 }}>
                    {getLabel('participant.scenarios.sorting')}
                </Typography>
                <Select
                    name="sort"
                    label={getLabel('participant.scenarios.sort.by')}
                    value={sort}
                    onChange={onSelectChange}
                    id="scenarios-list-mobile-sort"
                    options={DASHBOARD_SORT_OPTIONS.map((option) => ({
                        ...option,
                        label: getLabel(`participant.scenarios.sort.options.${option.value}`),
                    }))}
                    sx={{
                        container: {
                            marginBottom: 16,
                        },
                    }}
                />
                <Typography variant="h6" color="text.disabled" component="div" sx={{ marginBottom: 4 }}>
                    {getLabel('participant.scenarios.filters')}
                </Typography>
                <Typography variant="p1" color="text.primary" sx={{ marginBottom: 4 }}>
                    {getLabel('participant.scenarios.surveyStage')}
                </Typography>
                {Object.entries(tagFilters).map(([name, enabled]: any) => {
                    const Chip = Chips[name as keyof typeof Chips];
                    return (
                        <ChipContainer key={name}>
                            <Chip
                                disabled={!enabled}
                                selected={showMobileView && enabled}
                                onClick={() => onTagSelect(name, enabled)}
                            />
                        </ChipContainer>
                    );
                })}
            </Drawer>
        </>
    );
};
