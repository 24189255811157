import { styled } from '@mui/material/styles';
import IssueCatMobile  from 'Assets/svg/issueCatMobile.svg?react';
import IssueCatTabletDesktop  from 'Assets/svg/tabletDesktopIssueCat.svg?react';

export const Background = styled('div')(({ theme }) => ({
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.gradient.background,
    color: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
        padding: `0 ${theme.spacing(12)}`,
    },
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
    },
}));

export const TabletDesktopBlock = styled('div')(({ theme }) => ({
    width: 384,
    height: 384,
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
        marginRight: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
        width: 328,
        height: 356,
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

export const MobileImg = styled(IssueCatMobile)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

export const TabletDesktopImg = styled(IssueCatTabletDesktop)(() => ({
    order: 1,
}));
